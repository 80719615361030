import Vue from 'vue';

export function formatToLowercase(value) {
  if (!value) return '';
  const str = value.toString();
  return str.toLowerCase();
}

export function formatToCapitalize(value) {
  if (!value) return '';
  const str = value.toString();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatBytes(bytes, decimals = 2) {
  const lang = Vue.prototype.$getDictLanguage('intlIso');
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['byte', 'kilobit', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];
  let i = 0;
  let number = 0;

  if (bytes > 0) {
    i = Math.floor(Math.log(bytes) / Math.log(k));
    number = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }

  return Intl.NumberFormat(lang, {
    style: 'unit',
    unit: sizes[i],
  }).format(number);
}
