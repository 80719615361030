import { createMessenger } from '@userlike/messenger';

class UserLikeChat {
  constructor() {
    this.widgets = {
      authorized: {
        apiKey: '02a9e1fda22f401ca0ed9339d1add4f55dfb09bbd5df444b88ff4ca9c56b6d07',
        api: null,
      },
      guest: {
        apiKey: '8d5b18157ae649d9992d76f9c9134bd0a815cba1514b42c5bf34acc10215c58d',
        api: null,
      },
    };
    this.isWidgetInitializing = false;
    this.activeWidgetType = null;
  }

  getWidget(widgetType) {
    const widget = this.widgets[widgetType];
    return widget ? { ...widget, type: widgetType } : null;
  }

  getActiveWidget() {
    return this.getWidget(this.activeWidgetType);
  }

  async createUserLikeApi(apiKey) {
    const result = await createMessenger({
      version: 1,
      widgetKey: apiKey,
    });

    if (result.kind === 'error') throw new Error(result.error);

    const { api } = result.value;

    if (api === null) {
      throw new Error('Api reached end-of-life, please check documentation and upgrade.');
    }

    return api;
  }

  async initUserLikeMessenger(widgetType) {
    await this.removeActiveWidget();

    const widget = this.widgets[widgetType];

    if (!widget) {
      return;
    }

    if (!widget.api) {
      try {
        widget.api = await this.createUserLikeApi(widget.apiKey);
        // await this.userLikeMessengerApi.setVisibility({
        //   main: true,
        //   button: false,
        //   notifications: false,
        // });
      } catch (e) {
        console.log('UserLike error: ', e);
      }
    }

    await widget.api.mount();
    this.activeWidgetType = widgetType;

    return widget;
  }

  async setContactInfo(data = {}) {
    const activeWidget = this.getActiveWidget();

    if (activeWidget?.api) {
      await activeWidget.api.setContactInfo(data);
    }
  }

  async setCustomData(data = {}) {
    const activeWidget = this.getActiveWidget();

    if (activeWidget?.api) {
      await activeWidget.api.setCustomData(data);
    }
  }

  async unmount() {
    const activeWidget = this.getActiveWidget();

    if (activeWidget?.api) {
      await activeWidget.api.unmount();
    }
  }

  async mount() {
    const activeWidget = this.getActiveWidget();

    if (activeWidget?.api) {
      await activeWidget.api.mount();
    }
  }

  isUserLikeChatInited(widgetType) {
    const widget = this.getActiveWidget();
    return !!widget?.api && widget?.type === widgetType;
  }

  isUserLikeChatMounted(widgetType) {
    const activeWidget = this.getActiveWidget();

    if (!activeWidget?.api) return false;

    const isWidgetMounted = !activeWidget.api.getState()?.hidden;
    return isWidgetMounted && activeWidget.type === widgetType;
  }

  //
  async removeActiveWidget() {
    const activeWidget = this.getActiveWidget();

    if (activeWidget?.api) {
      await activeWidget.api.logout();
      await activeWidget.api.unmount();

      this.activeWidgetType = null;
    }
  }
}

export default {
  install(Vue) {
    const userLikeChat = new UserLikeChat();

    Vue.prototype.$chatWidget = Vue.observable(userLikeChat);

    Vue.prototype.$initUserLikeMessenger = async (widgetType, payload = {}) => {
      if (userLikeChat.isUserLikeChatMounted(widgetType)) return;

      const { contactInfo, customData } = payload;
      const isUserLikeChatInited = userLikeChat.isUserLikeChatInited(widgetType);

      if (isUserLikeChatInited) {
        await userLikeChat.mount();
        return;
      }

      if (userLikeChat.isWidgetInitializing) return;

      userLikeChat.isWidgetInitializing = true;
      await userLikeChat.initUserLikeMessenger(widgetType);

      if (contactInfo) {
        await userLikeChat.setContactInfo(contactInfo);
      }

      if (customData) {
        await userLikeChat.setCustomData(customData);
      }

      userLikeChat.isWidgetInitializing = false;
    };

    Vue.prototype.$hideUserLikeMessenger = async () => {
      return userLikeChat.unmount();
    };

    Vue.prototype.$getUserLikeChatState = () => {
      const activeWidget = userLikeChat.getActiveWidget();
      return activeWidget?.api?.getState();
    };
  },
};
