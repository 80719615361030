/*
  Entity list
 */

import Vue from 'vue';
import router from '@/router';
import {
  ADMINISTRATIVE_UNIT_TYPES,
  FIELD_VALUE_STYLES,
  LIST_TYPES,
} from "@/config/enums";
import store from "@/store";
import BaseList from "@/shared/classes/list/BaseList";
import { getAdministrativeUnits } from "@/api/repositories/geoRepository";
import { getIndexedArray, IndexedArray } from "@/shared/proxies";
import { mergeQueryParams, parseFilterFromUrlQuery } from "@/shared/utils";
import { getItemsId } from "@/shared/utils";
import _ from 'lodash';
import { convertQueryToJsonRpc } from "@/shared/api";
import { postFile } from "@/api/repositories/filesRepository";

const FieldsIndexedArray = getIndexedArray('key');

export default class EntityList extends BaseList {
  constructor(...args) {
    super(...args);

    this.routes = {};
    this.permissions = {};
    this.loadingActions = {
      print: false,
      excel: false,
    };

    this.userFieldset = [];
    this.fixedUserFieldset = undefined;
    this.hasAllItems = false;
    this.isLoadingMapItems = false;

    // entity fields
    this.requiredFields = []; // all required fields
    this.fieldsSections = new FieldsIndexedArray([]); // sections with fields as children

    // aggregation variables
    this.requiredCurrentFields = [];
    this.allFields = new FieldsIndexedArray([]); // all fields from fieldsSections map by field key
    this.currentFields = new FieldsIndexedArray([]);
    this.allCurrentFields = new FieldsIndexedArray([]); // all fields in right priority for use in list
    this.totalAggs = [];

    // filter with score sort
    this.filterKeysWithScore = [];

    this.initPerPage();
    this.selfClass = EntityList;
  }

  static getEntityProperty(propertyKey, defaultValue) {
    return this.entityClass[propertyKey] || defaultValue;
  }

  getExportFileNamePrefix() {
    const listTypes = Object.entries(LIST_TYPES).reduce((acum, [key, id]) => ({
      ...acum,
      [id]: key.toLowerCase(),
    }), {});

    return listTypes[this.listTypeId];
  }

  getListFiltersSetting() {
    return [];
  }

  getListSearchFields() {
    return [];
  }

  getListFiltersSettingFiltered() {
    return this.listFiltersSetting.filter(setting => {
      return !setting.listTypeIds || setting.listTypeIds.includes(this.listTypeId);
    });
  }

  async loadGeographyDataset(ids = {}) {
    const regionIds = (ids.regions || []).filter(regionId => !(this.dataset?.regions || {}).hasOwnProperty(regionId));
    const districtIds = (ids.districts || []).filter(districtId => !(this.dataset?.districts || {}).hasOwnProperty(districtId));

    if (!regionIds.length &&
      !districtIds.length) {
      return;
    }

    const query = {
      limit: regionIds.length + districtIds.length,
      offset: 0,
      filter: {
        region_id: {
          in: regionIds,
        },
        district_id: {
          in: districtIds,
        },
      },
      access_type: "strict",
    };
    const geoTypes = [
      {
        idKey: 'country_id',
        itemsKey: 'countries',
        typeId: ADMINISTRATIVE_UNIT_TYPES.COUNTRY,
      },
      {
        idKey: 'region_id',
        itemsKey: 'regions',
        typeId: ADMINISTRATIVE_UNIT_TYPES.REGION,
      },
      {
        idKey: 'district_id',
        itemsKey: 'districts',
        typeId: ADMINISTRATIVE_UNIT_TYPES.DISTRICT,
      },
    ];
    let res = {};

    try {
      const response = await getAdministrativeUnits(query);
      const items = response?.data || [];

      geoTypes.forEach(type => {
        res[type.itemsKey] = items.filter(item => item.administrative_unit_type_id === type.typeId);
      });
    } catch (error) {}

    geoTypes.forEach(type => {
      const resItems = res[type.itemsKey] || [];

      if (resItems.length > 0) {
        this.updateDataset(type.itemsKey, res[type.itemsKey], type.idKey);
      }
    });
  }

  goToAdd() {
    const addLink = this.selfClass.entityClass.getAddLink();

    if (addLink) {
      router.push(addLink);
    }
  }

  async doEntityAction(payload = {}) {
    const { action, item } = payload;

    switch (action.key) {
      case 'view':
        item.goToView();
        break;
      case 'edit':
        item.goToEdit();
        break;
      case 'delete':
        if (confirm(Vue.prototype.$vDict('global.text_are_you_sure.text'))) {
          await this.reqDeleteItem(item.id);
        }
        break;
      default:
        this.loadingItems.push(item.id);
        await item.doAction(action);
        this.loadingItems = this.loadingItems.filter(itemId => itemId !== item.id);
    }
  }

  /*
    Private methods
  */

  prepareFields(fields = [], countries = []) {
    const allowedCountries = countries.length > 0 ? countries : store.getters['Account/getSelectionsValue']('countries');
    const { fieldsBlackList } = this.selfClass.entityClass;

    const res = fields.filter(field => {
      const blackListCountries = fieldsBlackList[field.key]?.countries || [];
      let res = !blackListCountries.length || allowedCountries.some(countryId => !blackListCountries.includes(countryId));

      if (res) {
        res = !field.hasOwnProperty('countries') || field.countries.some(countryId => allowedCountries.includes(countryId));
      }

      if (res) {
        res = !field.hasOwnProperty('listTypeIds') || field.listTypeIds.includes(this.listTypeId);
      }

      return res;
    }).map(field => {
      let measure;

      switch (field.valueStyle) {
        case FIELD_VALUE_STYLES.CURRENCY:
          measure = store.getters['Account/userCurrencySymbol'].symbol;
          break;
        default:
      }

      const resField = {
        ...field,
        label: measure ? `${field.label}, ${measure}` : field.label,
      };

      if (resField.fields?.length > 0) {
        resField.fields = this.prepareFields(resField.fields, countries);
      }

      return resField;
    });

    return res;
  }

  /*
    Getters
  */

  getRequiredFields() { return []; }

  getFieldsSections() { return []; }

  getPrintSettingsFields() { return []; }

  getFiltersFromUrl() {
    const urlKeys = ['searchFilter', 'listFilter'];
    const res = {};

    urlKeys.forEach(urlKey => {
      const urlQuery = router.currentRoute.query[urlKey];

      if (urlQuery) {
        res[urlKey] = parseFilterFromUrlQuery(urlQuery);
      }
    });

    return res;
  }

  getAllSortableFields() {
    const allFields = [...this.requiredCurrentFields, ...this.allFields];
    const sortableFields = allFields.filter(field => field.sortable);

    if (this.filterKeysWithScore.length > 0 &&
      Object.keys(this.searchFilter).some(key => this.filterKeysWithScore.includes(key))) {
      sortableFields.unshift({
        key: '_score',
        label: Vue.prototype.$vDict('entity_list.sort_field_relevance_label.text'),
        sortable: true,
      });
    }

    return sortableFields;
  }

  getCurrentSortableFields() {
    if (this.hasAllItems) {
      return [];
    }

    const allSortableFields = this.getAllSortableFields();
    return allSortableFields.filter(field =>
      this.allCurrentFields.findById(field.key) ||
      this.currentSort.hasOwnProperty(field.key) ||
      this.defaultSort.hasOwnProperty(field.key) ||
      field.key === '_score',
    );
  }

  getCurrentSort() {
    const currentSortableFields = this.getCurrentSortableFields();
    let sort;

    if (!currentSortableFields.some(field => this.currentSort.hasOwnProperty(field.key))) {
      sort = this.defaultSort;
    } else if (this.config.isOnlyOneSort) {
      sort = _.defaults({}, this.currentSort);
    } else {
      const { nameFieldKey } = this.selfClass.entityClass;

      sort = _.defaults({}, this.currentSort, this.defaultSort, nameFieldKey ? {
        [nameFieldKey]: {
          order: 'a',
        },
      } : {});
    }

    return sort;
  }

  /*
    Init methods
  */

  initPrintFields() {
    const printSettingsFields = this.getPrintSettingsFields();

    this.printSettingsFields = this.prepareFields(printSettingsFields);
    this.fixedPrintFields = this.getFixedPrintFields();
  }

  initRequiredFields() {
    const requiredFields = this.getRequiredFields();
    this.requiredFields = this.prepareFields(requiredFields);
  }

  initFieldsSections(countries = []) {
    const fieldsSections = this.getFieldsSections();
    const sections = fieldsSections.map(section => {
      const sectionFields = section.fields || [];

      return {
        ...section,
        fields: this.prepareFields(sectionFields, countries),
      };
    });

    this.fieldsSections = new FieldsIndexedArray(sections);
  }

  // return all fields includes required and all fields
  filterFieldsForPrint(fields = []) {
    if (!this.printing) {
      return fields;
    }

    return fields.filter(field => !this.printFieldsBlacklist.includes(field.key));
  }

  initAllFields() {
    const allFields = this.fieldsSections.reduce((acum, section) => {
      const fields = section.selectable ? [section] : section.fields;

      return [
        ...acum,
        ...fields.map(field => {
          let sortable = !!field.sortable;

          if (typeof field.sortable === 'function') {
            sortable = field.sortable(this.statusId);
          }

          return {
            ...field,
            sortable,
            sectionKey: section.key,
          };
        }),
      ];
    }, []);

    this.allFields = new FieldsIndexedArray(allFields);
  }

  initRequiredCurrentFields() {
    this.requiredCurrentFields = this.filterFieldsForPrint(this.requiredFields);
  }

  initCurrentFields() {
    let fields = [];

    this.userFieldset.forEach(userField => {
      const field = this.allFields.findById(userField.key);
      const isFieldSelected = !userField.hasOwnProperty('checked') || userField.checked;

      if (field && isFieldSelected) {
        const subFields = field?.fields || [];
        const userSubFields = userField?.fields || [];

        fields.push({
          ...field,
          value: userField,
          fields: subFields.filter(subField => userSubFields.some(el => el.key === subField.key)),
        });
      }
    });

    fields = this.filterFieldsForPrint(fields);

    this.currentFields = new FieldsIndexedArray(fields);
  }

  // return all current fields includes required current fields and current fields
  initAllCurrentFields() {
    const allCurrentFields = [...this.requiredCurrentFields, ...this.currentFields];
    const fixedPriorityFields = [];
    const res = [];

    for (let i = allCurrentFields.length - 1; i >= 0; i--) {
      const field = allCurrentFields[i];

      if (field.fixedPriority) {
        fixedPriorityFields.push(field);
        allCurrentFields.splice(i,1);
      }
    }

    allCurrentFields.forEach(field => {
      const fixedPriorityField = fixedPriorityFields.find(el => el.beforeField === field.key);

      if (fixedPriorityField) {
        res.push(fixedPriorityField);
      }

      res.push(field);
    });

    this.allCurrentFields = new FieldsIndexedArray(res);
  }

  async initUserFieldset() {
    if (this.fixedUserFieldset) {
      this.userFieldset = this.fixedUserFieldset;
    } else {
      try {
        this.userFieldset = await store.dispatch('fetchFieldset', {
          listTypeId: this.listTypeId,
          countryId: store.getters['Account/getSettingsValue']('client.country_id'),
          type: this.config.isMain && this.config.showFieldsSettings ? 'userSettings' : '',
        });
      } catch (error) {
        console.log('Error fetchFieldset', error);
      }
    }

    if (this.config.couldHasCustomFields) {
      await store.dispatch('fetchCustomFieldsets', {
        entityTypeId: this.selfClass.entityClass.entityTypeId,
        clientId: store.getters['Account/getSettingsValue']('client.id'),
      });
    }
  }

  initCustomFields() {}

  initListFields() {
    this.initRequiredFields();
    this.initFieldsSections();

    if (this.printing) {
      this.initPrintFields();
    }

    if (this.config.couldHasCustomFields) {
      this.initCustomFields();
    }

    this.initAllFields();
    this.initRequiredCurrentFields();
    this.initCurrentFields();
    this.initAllCurrentFields();
  }

  initPermanentFilter() {}

  initPerPage() {
    if (this.config.isMain) {
      this.perPage = this.printing ?
        Number(process.env.VUE_APP_PRINT_MAX_LIMIT) :
        Number(process.env.VUE_APP_ITEMS_PER_PAGE);
    } else {
      this.perPage = this.printing ?
        Number(process.env.VUE_APP_PRINT_MAX_LIMIT_INNER) :
        Number(process.env.VUE_APP_ADMIN_ITEMS_PER_SUBPAGE);
    }
  }

  inheritListFiltersFromSearch() {
    const listTypeId = Number(router.currentRoute.query.listTypeId);
    const searchFilter = store.getters.getEntityListValue({
      listTypeId,
      key: 'searchFilter',
    });

    if (!searchFilter) {
      this.setListFilter({});
      return;
    }

    const listFilter = _.cloneDeep(this.listFilter);

    this.searchFilterInheritanceConfig.forEach(pfConfig => {
      const fKey = pfConfig.key;
      const sKey = pfConfig.searchKey || fKey;

      if (searchFilter[sKey]) {
        listFilter[fKey] = _.cloneDeep(searchFilter[sKey]);
      } else {
        delete listFilter[fKey];
      }
    });

    this.setListFilter(listFilter);
  }

  onListInited() {}

  async loadDataForInitList() {}

  async initListData() {
    if (!this.listDataInited) {
      this.initSavedViewMode();

      if (this.config.isSavePosition) {
        this.initSavedUserPosition();
      }
    }

    const payloadForInit = await this.loadDataForInitList();
    const urlFilters = this.getFiltersFromUrl();
    const clearFilter = router.currentRoute.query.clearFilter;
    const filters = {
      listFilter: null,
      searchFilter: null,
    };

    if (urlFilters.listFilter) {
      filters.listFilter = clearFilter ?
        urlFilters.listFilter :
        { ...this.defaultListFilter, ...urlFilters.listFilter };
    }

    if (urlFilters.searchFilter) {
      filters.searchFilter = clearFilter ?
        urlFilters.searchFilter :
        { ...this.defaultSearchFilter, ...urlFilters.searchFilter };
    }

    this.initDefaultValues();

    await this.initUserFieldset();

    this.initListFields();
    this.initPermanentFilter();
    this.initListFilter(filters.listFilter);
    this.initSearchFilter(filters.searchFilter);
    this.initCurrentSort();
    this.listFiltersSetting = this.getListFiltersSetting(payloadForInit);

    if (this.searchFilterInheritanceConfig.length > 0 &&
      router.currentRoute.params.fromMainList) {
      this.inheritListFiltersFromSearch();
    }

    this.onListInited();
    this.listDataInited = true;
  }

  async updateFieldset(fieldset = [], alternativeListTypeId) {
    store.dispatch('updateFieldset', {
      listTypeId: this.listTypeId,
      fieldset,
      alternativeListTypeId,
    });

    this.userFieldset = fieldset;
    this.initListFields();
  }

  loadDatasetEntities(entityClass, payload = {}) {
    const ids = payload.ids || [];
    const fields = payload.fields || [];

    entityClass.loadAllItemsFunc({
      limit: ids.length,
      offset: 0,
      filter: {
        id: {
          in: ids,
        },
      },
      fields,
    }).then(response => {
      const items = response?.data || [];
      this.updateDataset(entityClass.datasetKey, items);
    });
  }

  async beforePrepareItems(items = [], queryFields) {
    return items;
  }

  async prepareItems(items = [], _queryFields) {
    if (!items.length) {
      return [];
    }

    const queryFields = _queryFields || this.getQueryFields();
    let res = await this.beforePrepareItems(items, queryFields);

    res = await this.convertItemsToEntities(res);
    res = new IndexedArray(res);

    return Promise.resolve(res);
  }

  async convertItemsToEntities(items = []) {
    const userFieldset = this.allCurrentFields.map(field => field.key);

    return items.map(item => {
      const entity = new this.selfClass.entityClass(item, this);
      entity.prepareFieldsData(userFieldset, this.listTypeId);
      return entity;
    });
  }

  async exportToExcel({ fileName, ids }) {
    const listQuery = this.getQuery();
    const endpoint = this.selfClass.entityClass.loadAllItemsEndpoint || {};
    const reqQuery = convertQueryToJsonRpc(endpoint, listQuery);
    const fileData = {
      ...reqQuery,
      export_data: this.allCurrentFields.map(field => ({ key: field.key })),
    };
    const query = {
      list_type_id: this.listTypeId,
      file_type: 'xlsx',
      file_name: fileName,
      user_id: store.getters['Account/userId'],
      client_id: store.getters['Account/getSettingsValue']('client.id'),
      file_data: fileData,
    };

    if (ids) {
      query.jsFilter = {
        id: ids,
      };
    }

    return await postFile(query);
  }

  isAction(actionKey) {
    return this.loadingActions[actionKey];
  }

  goToPrint(payload = {}) {
    const { ids = [] } = payload;

    router.push({
      path: `${router.currentRoute.path}/print`,
      query: {
        listTypeId: this.listTypeId,
        destination: router.currentRoute.fullPath,
        statusId: this.statusId || undefined,
        ids: ids.join(','),
      },
    });
  }

  async doAction(actionKey, payload = {}) {
    this.loadingActions[actionKey] = true;

    switch (actionKey) {
      case 'print':
        this.goToPrint(payload);
        break;
      case 'excel':
        await this.exportToExcel(payload);
        break;
      default:
    }

    this.loadingActions[actionKey] = false;
  }

  hasAction(actionKey) {
    const actions = this.config.actions || {};
    const res = this.statusId ?
      _.get(actions, `${actionKey}.byStatus.${this.statusId}`) :
      _.get(actions, `${actionKey}.default`);

    return !!res;
  }

  async onChangeEntity(entity, patchData = {}) {
    const itemIndex = _.findIndex(this.items, el => el.id === entity.id);

    if (itemIndex === -1) {
      return;
    }

    const fieldset = Object.keys(patchData);
    entity.prepareFieldsData(fieldset, this.listTypeId);
    Vue.set(this.items, itemIndex, entity);
  }

  async loadTotalAggs(fields) {
    const allTotalQuery = this.getAllTotalQuery();
    const query = {
      ...allTotalQuery,
      offset: 0,
      limit: 0,
      aggs: {},
    };
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();

    const promises = fields
      .filter(field => !_.has(this.totalAggs, `${field.key}.${field.func}`))
      .map(field => loadItemsFunc({
        ...query,
        aggs: {
          [field.key]: {
            func: field.func,
          },
        },
    }));
    let responses = [];

    try {
      responses = await Promise.all(promises);
    } catch (e) { }

    if (!responses?.length) {
      return;
    }

    responses.forEach((val, index) => {
      const field = fields[index];
      const metaAggs = responses[index]?.meta?.aggs || {};
      const aggData = metaAggs[field.key];

      if (aggData) {
        this.totalAggs[field.key] = Object.assign({}, this.totalAggs[field.key], {
          [field.func]: aggData,
        });
      }
    });
  }

  getSubFields(fieldKey) {
    const currentField = this.currentFields.findById(fieldKey);
    return new FieldsIndexedArray(currentField?.fields || []);
  }

  async loadEntitiesDataset(_options = {}) {
    const options = {
      ids: [],
      queryParams: {},
      entityClass: null,
      isUserSection: false,
      ..._options,
    };

    if (!options.ids.length) {
      return;
    }

    const query = mergeQueryParams(options.queryParams, {
      filter: {
        id: {
          in: options.ids,
        },
      },
      limit: options.ids.length,
    });
    const { entityClass } = options;

    try {
      let response;

      if (options.isUserSection) {
        Object.assign(query, {
          context: 'client',
        });
        response = await entityClass.loadUserItemsFunc(query);
      } else {
        response = await entityClass.loadAllItemsFunc(query);
      }

      const items = response?.data || [];

      this.updateDataset(entityClass.datasetKey, items);
    } catch (e) {}
  }

  loadEntitiesDatasets(loadEntitiesData, items) {
    loadEntitiesData.forEach(loadData => {
      const { entityClass, queryParams } = loadData;
      const itemsIds = getItemsId(items, 'entity_id', {
        entity_type_id: entityClass.entityTypeId,
      });

      this.loadEntitiesDataset({
        ids: itemsIds,
        queryParams: {
          ...queryParams,
          fields: [...queryParams.fields, 'users'],
        },
        entityClass,
        isUserSection: true,
      })
        .then(() => {
          const datasetData = this.dataset[entityClass.datasetKey] || new IndexedArray([]);
          const notFoundIds = itemsIds.filter(id => !datasetData.findById(id));
          this.loadEntitiesDataset({
            ids: notFoundIds,
            queryParams,
            entityClass,
          });
        });
    });
  }

  async refetchItem(entityId, overrideQuery = {}) {
    const lastQuery = this.getLastQuery();
    const query = mergeQueryParams(lastQuery, {
      offset: 0,
      limit: 1,
      filter: {
        id: {
          in: [entityId],
        },
      },
    }, overrideQuery);

    try {
      const loadItemsFunc = this.selfClass.getLoadItemsFunc();
      const response = await loadItemsFunc(query);

      return response?.data[0];
    } catch (error) {
      console.log(error);
    }
  }

  async reloadEntity(entity, overrideQuery = {}) {
    const data = await this.refetchItem(entity.id, overrideQuery);
    if (data) {
      const [updatedEntity] = await this.prepareItems([data]);
      await this.onChangeEntity(updatedEntity);
    }
  }
}
