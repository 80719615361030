<template>
  <div class="form-field-selector ffs">
    <div
      v-if="isTags && tags.length > 0"
      class="ffs-tags"
    >
      <transition-expand>
        <div class="ffs-tags__list">
          <transition-group
            data-cy="ffs-tags-list"
            name="tags-appear"
            tag="div"
            class="ffs-tags__list-anim"
          >
            <base-tag
              v-for="(tag, index) in tags"
              :key="`tag-${index}`"
              :name="tag.name || tag.title"
              show-delete-button
              @delete="onTagDelete(tag.id)"
            />
          </transition-group>
        </div>
      </transition-expand>
    </div>
    <div class="ffs-header">
      <div class="ffs-header__title">
        {{ cTitle }}
      </div>

      <div class="ffs-header__actions">
        <transition
          name="opacity-anim"
          mode="out-in"
        >
          <b-link
            v-if="isButtonClearAll && isSelectedValue"
            key="clear-all"
            data-cy="link-clear-all"
            @click="clearAll"
          >
            {{ getText('button-clear-all') }}
          </b-link>
          <b-link
            v-else-if="isButtonSelectAll && !isSelectedValue"
            key="select-all"
            data-cy="link-select-all"
            @click="selectAll"
          >
            {{ getText('button-select-all') }}
          </b-link>
        </transition>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import { getIdsFromArray, findById, upLevelIdsDeep } from '@/shared/utils';

const TransitionExpand = () => import('@/components/animation/TransitionExpand');
const BaseTag = () => import('@/components/tags/BaseTag');

export default {
  name: 'form-field-selector',
  components: {
    TransitionExpand,
    BaseTag,
  },
  props: {
    isTags: { type: Boolean, default: false },
    idKey: { type: String, default: 'id' },
    title: { type: String, default: '' },
    value: { type: Array, default: Array },
    items: { type: Array, default: Array },
    isButtonClearAll: { type: Boolean, default: true },
    isButtonSelectAll: { type: Boolean, default: true },
    buttonTextClearAll: { type: String, default: '' },
    buttonTextSelectAll: { type: String, default: '' },
  },
  computed: {
    tags() {
      const tagIds = upLevelIdsDeep(this.items, this.value);
      return findById(this.items, tagIds);
    },
    cTitle() {
      return this.title ? `${this.title}:` : '';
    },
    isSelectedValue() {
      return this.value.length > 0;
    },
  },
  methods: {
    onTagDelete(tagId) {
      const tag = this.tags.find(el => el.id === tagId);

      if (tag) {
        const children = tag.items || [];
        let res = [];

        if (children.length > 0) {
          const itemIds = getIdsFromArray(children);
          res = this.value.filter(itemId => !itemIds.includes(itemId));
        } else {
          res = this.value.filter(itemId => itemId !== tag.id);
        }

        this.$emit('input', res);
      }
    },
    getText(key) {
      let res = '';

      switch (key) {
        case 'button-clear-all':
          res = this.buttonTextClearAll || this.$vDict('global.button_clear_all.text');
          break;
        case 'button-select-all':
          res = this.buttonTextSelectAll || this.$vDict('global.button_select_all.text');
          break;
        default:
      }

      return res;
    },
    clearAll() {
      this.$emit('input', []);
    },
    selectAll() {
      const itemsId = getIdsFromArray(this.items, {
        idKey: this.idKey,
      });
      this.$emit('input', itemsId);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/variables';

  .ffs-header {
    margin-bottom: 4px;
    display: flex;
    align-items: baseline;
    border-top: 1px solid var(--wizard-step-line-color-lg, $wizard-step-line-color-lg);

    &__title {
      font-family: var(--font-heading, $font-heading);
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      flex: 1 1;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__actions {
      // min-width: 5px;
      // min-height: 34px;
      text-align: right;
      color: var(--triggers-filter-action-link-color, $triggers-filter-action-link-color);
      font-family: var(--font-heading, $font-heading);
      text-transform: uppercase;
      font-weight: bold;
      line-height: 100%;

      a {
        display: inline-block;
        padding: 10px 0;
        color: currentColor;
        letter-spacing: 1px;

        &:hover {
          text-decoration: underline;
          color: var(--triggers-filter-action-link-hover-color, $triggers-filter-action-link-hover-color);
        }
      }
    }
  }
</style>
