import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

export default [
  dashboardContainer({
    meta: {
      breadcrumb: { name: 'myran_logs' },
      menuId: 44,
    },
    path: '/myran-logs',
    redirect: '/myran-logs/client-versions',
    name: 'MyranLogs',
    children: [
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'Client versions' },
          menuId: 46,
        },
        path: '/myran-logs/client-versions',
        name: 'ClientVersions',
        component: () =>
          import(/* webpackChunkName: "myran-logs-client-versions" */ '@/views/myran-logs/ClientVersions'),
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'Subscription versions' },
          menuId: 47,
        },
        path: '/myran-logs/subscription-versions',
        name: 'SubscriptionVersions',
        component: () =>
          import(/* webpackChunkName: "myran-logs-subscription-versions" */ '@/views/myran-logs/SubscriptionVersions'),
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'Deactivated users' },
          menuId: 49,
        },
        path: '/myran-logs/suspended-users',
        name: 'SuspendedUsers',
        component: () =>
          import(/* webpackChunkName: "myran-logs-suspended-users" */ '@/views/myran-logs/SuspendedUsers'),
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'User profiles' },
          dict: {
            name: 'MyranLogsUserProfiles',
            vocabularies: ['myran_logs_user_profiles'],
          },
          menuId: 48,
        },
        path: '/myran-logs/user-profiles',
        name: 'UserProfiles',
        component: () => import(/* webpackChunkName: "myran-logs-user-profiles" */ '@/views/myran-logs/UserProfiles'),
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'User versions' },
          menuId: 45,
        },
        path: '/myran-logs/user-versions',
        name: 'UserVersions',
        component: () => import(/* webpackChunkName: "myran-logs-user-versions" */ '@/views/myran-logs/UserVersions'),
      },
    ],
  }),
];
