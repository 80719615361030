import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_MY_PROJECTS = {
  name: 'MyProjects',
  vocabularies: [
    'entity_list',
    'projects',
    'triggers',
    'search',
    'projects',
    'project',
    'notes',
    'activities',
    'global',
    'companies',
  ],
};

const DICTIONARY_PROJECT = {
  name: 'Project',
  vocabularies: [
    'entity_list',
    'projects',
    'project',
    'company',
    'triggers',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'notes',
    'activities',
    'search',
  ],
};

export const projects = [
  {
    path: '/sales/my-projects',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/sales/my-projects',
        name: 'MyProjects',
        component: () => import(/* webpackChunkName: "my-projects" */ '@/views/sales/MyProjects'),
        meta: {
          requiredQuery: {
            status: 1,
          },
          component: 'user_projects',
          middleware: [validateComponent],
          breadcrumb: { name: 'my_projects' },
          dict: DICTIONARY_MY_PROJECTS,
          menuId: 2,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'project', dynamic: true },
          component: 'user_projects',
          middleware: [validateComponent],
          dict: DICTIONARY_PROJECT,
          parentMenuId: 2,
        },
        path: ':id',
        name: 'MyProject',
        component: () => import(/* webpackChunkName: "project" */ '@/views/leads/Project'),
      },
    ],
  },
];

export const printProjects = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_MY_PROJECTS,
    },
    path: '/sales/my-projects/print',
    name: 'PrintUserProjects',
    component: () => import(/* webpackChunkName: "print-projects" */ '@/views/print/PrintProjects'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_PROJECT,
    },
    path: '/sales/my-projects/:id/print',
    name: 'PrintUserProject',
    component: () => import(/* webpackChunkName: "print-project" */ '@/views/print/print-pages/PagePrintProject'),
  },
];
