import validateComponent from '@/router/middleware/validateComponent';

export default [
  {
    path: 'dead-messages',
    redirect: '/system/dead-messages',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Dead messages' },
          component: 'dead_messages_list',
          middleware: [validateComponent],
          menuId: 73,
          dict: {
            name: 'SystemDeadMessages',
            vocabularies: ['dead_messages', 'search', 'dead_message_subscriptions'],
          },
        },
        path: '/system/dead-messages',
        name: 'SystemDeadMessages',
        component: () =>
          import(
            /* webpackChunkName: "dead-messages" */ '@/views/system/dead-messages/DeadMessagesList'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit dead message', dynamic: true },
          component: 'dead_messages_list',
          middleware: [validateComponent],
          parentMenuId: 73,
          dict: {
            name: 'SystemDeadMessageEditor',
            vocabularies: ['dead_messages'],
          },
        },
        path: ':id/edit',
        name: 'SystemDeadMessageEditor',
        component: () =>
          import(
            /* webpackChunkName: "dead-message-editor" */ '@/views/system/dead-messages/EditorDeadMessage'
          ),
      },
    ],
  },
];
