import validateComponent from "@/router/middleware/validateComponent";

const DICTIONARY_SYSTEM_DEFAULT_EXPORT_FIELDSETS = {
  name: 'SystemDefaultExportFieldsets',
  vocabularies: [
    'default_export_fieldsets',
    'default_fieldsets',
    'search',
    'projects',
    'tenders',
    'companies',
    'contacts',
  ],
};

export default [
  {
    path: 'default-export-fieldsets',
    redirect: '/system/default-export-fieldsets',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'country_export_fieldsets',
          middleware: [validateComponent],
          breadcrumb: { name: 'Default export fieldsets' },
          menuId: 105,
          dict: DICTIONARY_SYSTEM_DEFAULT_EXPORT_FIELDSETS,
        },
        path: '/system/default-export-fieldsets',
        name: 'SystemDefaultExportFieldsets',
        component: () =>
          import(
            /* webpackChunkName: "default-export-fieldset" */ '@/views/system/default-export-fieldsets/ListDefaultExportFieldsets.vue'
          ),
      },
      {
        meta: {
          component: 'country_export_fieldsets',
          middleware: [validateComponent],
          breadcrumb: { name: 'Edit default export fieldset', dynamic: true },
          parentMenuId: 105,
          dict: DICTIONARY_SYSTEM_DEFAULT_EXPORT_FIELDSETS,
        },
        path: ':id/edit',
        name: 'SystemDefaultExportFieldsetsEditor',
        component: () =>
          import(
            /* webpackChunkName: "default-export-fieldset" */ '@/views/system/default-export-fieldsets/EditorDefaultExportFieldset.vue'
            ),
      },
      {
        meta: {
          component: 'country_export_fieldsets',
          middleware: [validateComponent],
          breadcrumb: { name: 'Create default export fieldset' },
          parentMenuId: 105,
          dict: DICTIONARY_SYSTEM_DEFAULT_EXPORT_FIELDSETS,
        },
        path: 'new',
        name: 'SystemDefaultExportFieldsetsNew',
        component: () =>
          import(
            /* webpackChunkName: "default-dashboard-widgets" */ '@/views/system/default-export-fieldsets/EditorDefaultExportFieldset.vue'
          ),
      },
    ],
  },
];
