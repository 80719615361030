import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SYSTEM_COUNTRY_MODULES = {
  name: 'SystemCountryModules',
  vocabularies: ['country_modules'],
};

export default [
  {
    path: 'country-modules',
    redirect: '/system/country-modules',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Country modules' },
          component: 'country_modules_list',
          middleware: [validateComponent],
          menuId: 98,
          dict: DICTIONARY_SYSTEM_COUNTRY_MODULES,
        },
        path: '/system/country-modules',
        name: 'SystemCountryModules',
        component: () =>
          import(
            /* webpackChunkName: "country-modules" */ '@/views/system/country-modules/CountryModulesList.vue'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit country modules', dynamic: true },
          component: 'country_modules_list',
          middleware: [validateComponent],
          parentMenuId: 98,
          dict: DICTIONARY_SYSTEM_COUNTRY_MODULES,
        },
        path: ':id/edit',
        name: 'SystemCountryModulesEditor',
        component: () =>
          import(
            /* webpackChunkName: "country-modules" */ '@/views/system/country-modules/CountryModulesEditor.vue'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Create country modules', dynamic: true },
          component: 'country_modules_list',
          middleware: [validateComponent],
          parentMenuId: 98,
          dict: DICTIONARY_SYSTEM_COUNTRY_MODULES,
        },
        path: 'new',
        name: 'SystemCountryModulesNew',
        component: () =>
          import(
            /* webpackChunkName: "country-modules" */ '@/views/system/country-modules/CountryModulesEditor.vue'
          ),
      },
    ],
  },
];
