import _compact from 'lodash/compact';
import { COUNTRIES } from '@/config/enums';

export function formatOrgNumber(org_nr, country) {
  let data = [];
  let res;

  switch (country) {
    case COUNTRIES.SWEDEN:
      data = _compact([org_nr.substring(0, org_nr.length - 4), org_nr.substring(org_nr.length - 4, org_nr.length)]);
      res = data.join('-');
      break;
    case COUNTRIES.DENMARK:
      res = org_nr;
      break;
    case COUNTRIES.FINLAND:
      data = _compact([org_nr.substring(0, org_nr.length - 1), org_nr.substring(org_nr.length - 1, org_nr.length)]);
      res = data.join('-');
      break;
    case COUNTRIES.NORWAY:
      res = org_nr ? org_nr.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : '';
      break;
    default:
      res = org_nr;
  }

  return res;
}
