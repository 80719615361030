import guest from '@/router/middleware/guest';

const DICTIONARY_INVITE = {
  name: 'Invite',
  vocabularies: ['invite', 'password', 'global', 'errors', 'forms'],
  lookups: ['global'],
};

export default [
  {
    meta: {
      breadcrumb: { name: 'invite' },
      component: 'invite',
      dict: DICTIONARY_INVITE,
      auth: true,
    },
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "user-invite" */ '@/views/pages/user-invite/UserInvite'),
  },
  {
    meta: {
      component: 'invite',
      dict: DICTIONARY_INVITE,
      auth: true,
      systemPage: true,
    },
    path: '/invite-sso',
    name: 'InviteSso',
    component: () => import(/* webpackChunkName: "user-invite" */ '@/views/pages/InviteSso'),
  },
  {
    meta: {
      breadcrumb: { name: 'login' },
      middleware: [guest],
      dict: {
        name: 'Login',
        lookups: ['global'],
        vocabularies: ['login', 'password', 'errors', 'invite', 'notif_tmpl_general'],
      },
      auth: true,
      forbiddenForUserSubdomain: true,
    },
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/login/Login'),
  },
  {
    meta: {
      dict: {
        name: 'Oauth',
        lookups: ['global'],
        vocabularies: ['login', 'oauth'],
      },
      auth: true,
      forbiddenForUserSubdomain: true,
    },
    path: '/oauth',
    name: 'OAuth',
    component: () => import(/* webpackChunkName: "oauth" */ '@/views/pages/oauth/OAuth'),
  },
];
