import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

const DICTIONARY_SUPPORT_USER_TRANSFER_TASKS = {
  name: 'SupportUserTransferTasks',
  vocabularies: ['search', 'user_transfer_tasks'],
};

export default [
  dashboardContainer({
    meta: {
      breadcrumb: { name: 'logs' },
      menuId: 71,
    },
    path: '/logs',
    redirect: '/logs/email-logs',
    name: 'Logs',
    children: [
      {
        path: 'email-logs',
        redirect: '/logs/email-logs',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'email_sender_logs',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Email logs',
              },
              dict: {
                name: 'SupportEmailLogs',
                vocabularies: ['email_sender_logs', 'email_sender_events'],
              },
              menuId: 68,
            },
            path: '/logs/email-logs',
            name: 'SupportEmailLogs',
            component: () =>
              import(/* webpackChunkName: "support-email-logs" */ '@/views/support/email-logs/EmailLogs'),
          },
        ],
      },
      {
        path: 'ssn',
        redirect: '/logs/ssn-list',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'ssn_list',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Saved search notifications',
              },
              dict: {
                name: 'SupportSsnList',
                vocabularies: ['saved_search_notifs', 'users'],
              },
              menuId: 69,
            },
            path: '/logs/ssn-list',
            name: 'SupportSSNList',
            component: () =>
              import(/* webpackChunkName: "support-ssn-list" */ '@/views/support/ssn/SavedSearchNotifications'),
          },
        ],
      },
      {
        path: 'user-transfer-tasks',
        redirect: '/logs/user-transfer-tasks',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'user_transfer_tasks',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'User transfer tasks',
              },
              dict: DICTIONARY_SUPPORT_USER_TRANSFER_TASKS,
              menuId: 70,
            },
            path: '/logs/user-transfer-tasks',
            name: 'SupportUserTransferTasks',
            component: () =>
              import(
                /* webpackChunkName: "support-user-transfer-tasks" */ '@/views/support/user-transfer-tasks/UserTransferTasks'
              ),
          },
          {
            meta: {
              breadcrumb: { name: 'Create user transfer task' },
              component: 'user_transfer_tasks',
              middleware: [validateComponent],
              dict: DICTIONARY_SUPPORT_USER_TRANSFER_TASKS,
              parentMenuId: 70,
            },
            path: 'new',
            name: 'SupportEditorUserTransferTaskNew',
            component: () =>
              import(
                /* webpackChunkName: "support-user-transfer-task-editor" */ '@/views/support/user-transfer-tasks/UserTransferTaskEditor'
              ),
          },
        ],
      },
    ],
  }),
];
