import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SYSTEM_CUSTOM_FIELDSET_TEMPLATES = {
  name: 'SystemCustomFieldsetTemplates',
  vocabularies: ['entity_list', 'custom_fieldset'],
};

export default [
  {
    path: 'custom-fieldset-templates',
    redirect: '/system/custom-fieldset-templates',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Custom fieldset templates' },
          component: 'custom_field_templates_list',
          middleware: [validateComponent],
          menuId: 75,
          dict: DICTIONARY_SYSTEM_CUSTOM_FIELDSET_TEMPLATES,
        },
        path: '/system/custom-fieldset-templates',
        name: 'SystemCustomFieldsetTemplates',
        component: () =>
          import(
            /* webpackChunkName: "custom-fieldset-templates" */ '@/views/system/custom-fieldset-templates/CustomFieldsetTemplates'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit custom field templates', dynamic: true },
          component: 'custom_field_templates_list',
          middleware: [validateComponent],
          parentMenuId: 75,
          dict: DICTIONARY_SYSTEM_CUSTOM_FIELDSET_TEMPLATES,
        },
        path: ':id/edit',
        name: 'SystemCustomFieldsetTemplateEditor',
        component: () =>
          import(
            /* webpackChunkName: "custom-fieldset-template-editor" */ '@/views/system/custom-fieldset-templates/EditorCustomFieldsetTemplate'
          ),
      },
    ],
  },
];
