import components from './components';
import modules from './modules';
import defaultFieldsets from './default-fieldsets';
import deadMessages from './dead-messages';
import deadMessageSubscriptions from './dead-message-subscriptions';
import customFieldsetTemplates from './custom-fieldset-templates';
import customFieldMetrics from './custom-field-metrics';
import customFieldSelectionMetrics from './custom-field-selection-metrics';
import errorMessages from './error-messages';
import licenses from './licenses';
import defaultDashboardWidgets from './default-dashboard-widgets';
import countryModules from './country-modules';
import defaultExportFieldsets from './default-export-fieldsets';
import { dashboardContainer } from '@/router/containers';

export default [
  dashboardContainer({
    meta: {
      breadcrumb: { name: 'system' },
      menuId: 36,
    },
    path: '/system',
    redirect: '/system/components',
    name: 'System',
    children: [
      ...components,
      ...modules,
      ...defaultFieldsets,
      ...deadMessages,
      ...deadMessageSubscriptions,
      ...customFieldsetTemplates,
      ...customFieldMetrics,
      ...customFieldSelectionMetrics,
      ...errorMessages,
      ...licenses,
      ...defaultDashboardWidgets,
      ...countryModules,
      ...defaultExportFieldsets,
    ],
  }),
];
