import validateComponent from '@/router/middleware/validateComponent';

export const files = [
  {
    meta: {
      component: 'user_files',
      middleware: [validateComponent],
      dict: {
        name: 'MyFiles',
        vocabularies: ['user_files', 'search'],
      },
      menuId: 59,
    },
    path: 'my-files',
    name: 'MyFiles',
    component: () => import(/* webpackChunkName: "my-files" */ '@/views/sales/MyFiles'),
  },
];
