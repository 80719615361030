import validateComponent from '@/router/middleware/validateComponent';

export const notes = [
  {
    meta: {
      breadcrumb: { name: 'my_notes' },
      component: 'user_notes',
      middleware: [validateComponent],
      dict: {
        name: 'MyNotes',
        vocabularies: ['projects', 'project', 'tenders', 'companies', 'contacts', 'sales_notes', 'notes', 'search'],
        lookups: ['client'],
      },
      menuId: 7,
    },
    path: 'my-notes',
    name: 'MyNotes',
    component: () => import(/* webpackChunkName: "my-notes" */ '@/views/sales/MyNotes'),
  },
];
