import validateComponent from '@/router/middleware/validateComponent';

export default [
  {
    path: 'licenses',
    redirect: '/system/licenses',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Licenses' },
          component: 'all_licenses',
          middleware: [validateComponent],
          menuId: 93,
        },
        path: '/system/licenses',
        name: 'SystemLicenses',
        component: () =>
          import(/* webpackChunkName: "licenses" */ '@/views/system/licenses/LicensesList'),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit License', dynamic: true },
          component: 'all_licenses',
          middleware: [validateComponent],
          parentMenuId: 93,
        },
        path: ':id/edit',
        name: 'SystemLicenseEditor',
        component: () =>
          import(/* webpackChunkName: "licenses" */ '@/views/system/licenses/LicenseEditor'),
      },
    ],
  },
];
