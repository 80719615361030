/*
  CRMEntity class for extends
 */

import Vue from 'vue';
import store from '@/store';
import {
  COMMUNICATION_TYPES,
  COUNTRIES,
  USER_PROJECT_STATUSES,
  ENV_MODES,
  RESPONSIBLE_USER_STATUS,
} from '@/config/enums';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { formatRangeCost, formatNumber } from '@/shared/formatters';
import { getExternalLink, isEmptyValue, roundNumber } from '@/shared/utils';
import { getNotes, getActivities } from '@/api/repositories/salesRepository';
import { getUsers } from '@/api/repositories/userRepository';

const FieldTitle = () => import('@/components/entity-fields/FieldTitle.vue');

const RESPONSIBLE_USER_STATUS_ORDER = {
  [RESPONSIBLE_USER_STATUS.ACTIVE]: 1,
  [RESPONSIBLE_USER_STATUS.INACTIVE]: 2,
  [RESPONSIBLE_USER_STATUS.UNKNOWN]: 3,
};

//
export default class CRMEntity extends BaseEntity {
  static statusKey = '';
  static isUserSection = false;
  static titleComponent = FieldTitle;

  constructor(...args) {
    super(...args);

    //
    this.selfClass = CRMEntity;
    this.statusNewId = undefined;
  }

  static getMapParams = () => ({});

  static getEntityStatuses(statuses = []) {
    return statuses.sort((a, b) => (a.unimportant === b.unimportant ? 0 : a.unimportant ? 1 : -1));
  }

  static getStatuses() {
    return [];
  }

  static getUnknownStatus() {}

  static async loadItemsFromAllScopes(query = {}) {
    let res;

    try {
      res = await this.loadAllItemsFunc(query);
    } catch (e) {}

    if (res?.meta?.total || !this.loadUserItemsFunc) {
      return res;
    }

    try {
      res = await this.loadUserItemsFunc({ ...query, context: 'client' });

      if (res?.meta?.total) {
        res.isUserSection = true;
      }
    } catch (e) {}

    return res;
  }

  static fetchUserSectionContentStats() {
    store.dispatch('Account/fetchUserContentStats', {
      entityTypeId: this.entityTypeId,
      isShowLocked: store.getters.getEntityListValue({
        listTypeId: this.userSectionListTypeId,
        key: 'isShowLockedItems',
      }),
    });
  }

  isViewed() {
    return !!this.getValue('viewed');
  }

  isLocked() {
    return !!this.getValue('locked');
  }

  hasLockedReason() {
    return this.isLocked() && this.selfClass.loadLockedReason;
  }

  isDeleted() {
    return !!this.getValue('deleted') && !this.isLocked();
  }

  getMapData() {
    return {
      id: this.getValue('id'),
      title: this.getName(),
      location: this.getValue('location'),
      category_id: this.getValue('category_id'),
      _cluster: this.getValue('_cluster'),
    };
  }

  updateStatusFunc = async () => {};

  getStatusValue() {
    return this.data[this.selfClass.statusKey] || null;
  }

  async updateStatus(statusId) {
    const { statusKey } = this.selfClass;
    await this.patchData({
      [statusKey]: statusId,
    });
  }

  getAdministrativeUnit(itemId, fieldKey, dataset) {
    let datasetKey = '';
    let res = '';

    switch (fieldKey) {
      case 'country_id':
        datasetKey = 'countries';
        break;
      case 'region_id':
        datasetKey = 'regions';
        break;
      case 'district_id':
        datasetKey = 'districts';
        break;
      default:
    }

    if (datasetKey && dataset[datasetKey]) {
      res = this.getDatasetItem(dataset, datasetKey, itemId);
    }

    return res?.title || '';
  }

  getCommunicationsInfo(communicationTypes = []) {
    const communications = this.getValue('communications', {});
    return this.getCommunicationsInfoFrom(communications, communicationTypes);
  }

  getCommunicationsInfoFrom(communications, communicationTypes = []) {
    let res = [];

    communicationTypes.forEach((communicationTypeId) => {
      const commItems = _.get(communications, communicationTypeId) || [];
      const resCommunications = commItems.map((communication) => {
        const userCountryId = store.getters['Account/getSettingsValue']('client.country_id');
        const communicationProp = COMMUNICATION_TYPES.properties[communicationTypeId] || {};
        let value = null;
        let isLocal = false;

        switch (communicationTypeId) {
          case COMMUNICATION_TYPES.PHONE:
          case COMMUNICATION_TYPES.FAX:
          case COMMUNICATION_TYPES.MOBILE:
            if (communication.phone_data) {
              if (userCountryId === communication.phone_data.country_id && userCountryId !== COUNTRIES.FINLAND) {
                value = _.compact([communication.phone_data.area_code, communication.phone_data.number]).join('-');
              } else {
                value = communication.phone_data.display_number;
              }

              isLocal = communication.phone_data.country_id === userCountryId;
            }
            break;
          case COMMUNICATION_TYPES.WEBSITE:
            value = getExternalLink(communication.value);
            break;
          default:
        }

        return {
          ...communication,
          ...communicationProp,
          isLocal,
          value: value || communication.value,
          iconTooltip: Vue.prototype.$vDict(`global.tooltip_${communicationProp.key}.text`),
        };
      });

      res = res.concat(resCommunications);
    });

    return res;
  }

  getCompanyContactRoles(roles) {
    return roles
      .map((role) => {
        const companyRole = Vue.prototype.$lFind('client.company_roles', { id: role.company_role_id });
        let contactRole = Vue.prototype.$lFind('client.contact_roles', { id: role.contact_role_id });

        if (role.replacement_name) {
          contactRole = Object.assign({}, contactRole, {
            name: role.replacement_name,
          });
        }

        return {
          ...role,
          companyRole,
          contactRole,
        };
      })
      .filter((el) => el.companyRole || el.contactRole);
  }

  getCurrencyRange() {
    const userCurrency = store.getters['Account/getSettingsValue']('user.currency_id');
    const currencyRange = this.data[`currency_${userCurrency}_range`];
    const currencyValue = this.data[`currency_${userCurrency}_value`];
    let res = [];

    if (currencyRange && (!isEmptyValue(currencyRange.gte) || !isEmptyValue(currencyRange.lte))) {
      res = currencyRange.gte === currencyRange.lte ? [currencyRange.gte] : [currencyRange.gte, currencyRange.lte];
    } else if (!isEmptyValue(currencyValue)) {
      res = [currencyValue];
    }

    return res;
  }

  getCurrencyRangeAsText(range) {
    const currencyRange = range || this.getCurrencyRange();

    return currencyRange.length > 0
      ? formatRangeCost(currencyRange, {
          round: this.data.original_currency_id !== store.getters['Account/getSettingsValue']('user.currency_id'),
        })
      : null;
  }

  getCatDevTypes() {
    const categories = Vue.prototype.$lDict('client.project_categories', { attr: 'mapItems' });
    const devTypes = Vue.prototype.$lDict('client.development_types', { attr: 'mapItems' });
    const catDevTypes = this.getValue('cat_dev_type', []);
    const res = [];

    if (catDevTypes.length > 0) {
      catDevTypes.forEach((cdt) => {
        const category = categories[cdt.category_id];

        if (category) {
          const parentCategory = category?.parent_id ? categories[category.parent_id] : null;

          res.push({
            ...cdt,
            category: {
              ...category,
              icon: parentCategory?.icon,
              name: cdt.replacement_name || category.name,
            },
            dev_type: devTypes[cdt.dev_type_id],
          });
        }
      });
    }

    return res.sort((a, b) => a.priority - b.priority);
  }

  async addResponsible(userId, isChangeStatus = false) {
    const { addResponsibleFunc } = this.selfClass;
    let res;

    if (!addResponsibleFunc) {
      return;
    }

    try {
      const response = await addResponsibleFunc(this.data.id, { responsible_id: userId });

      if (response?.data) {
        const entityResponsible = this.getValue('responsible_ids', []);
        const data = {
          responsible_ids: _.union(entityResponsible, [userId]),
        };

        // data about new status for entity
        res = !_.isEmpty(response?.data) ? response?.data : {};

        if (isChangeStatus) {
          Object.assign(data, res);
        }

        this.patchData(data);
      }
    } catch (error) {
      Vue.prototype.$notifUnexpectedError();
    }

    return res;
  }

  async deleteResponsible(userId) {
    const { deleteResponsibleFunc } = this.selfClass;
    let response;

    if (deleteResponsibleFunc) {
      try {
        response = await deleteResponsibleFunc(this.data.id, { responsible_id: userId });

        if (response) {
          const entityResponsible = this.getValue('responsible_ids', []);

          this.patchData({
            responsible_ids: entityResponsible.filter((el) => el !== userId),
          });
        }
      } catch (error) {
        Vue.prototype.$notifUnexpectedError();
      }
    }

    return response;
  }

  getResponsibles(datasetUsers = []) {
    const clientUsers = store.getters['UserClient/users'];
    const allUsers = _.unionBy(clientUsers, datasetUsers, 'id');
    const responsibleIds = this.getValue('responsible_ids', []);

    const responsibleUsers = responsibleIds.map((responsibleId) => {
      const user = allUsers.find((el) => el.id === responsibleId);
      const isCoworker = clientUsers.some((el) => el.id === responsibleId);

      if (user && isCoworker) {
        return {
          ...user,
          responsibleStatus: RESPONSIBLE_USER_STATUS.ACTIVE,
        };
      }

      if (user && !isCoworker) {
        return {
          ...user,
          responsibleStatus: RESPONSIBLE_USER_STATUS.INACTIVE,
        };
      }

      return {
        id: responsibleId,
        name: Vue.prototype.$vDict('global.unknown_user_name.text'),
        responsibleStatus: RESPONSIBLE_USER_STATUS.UNKNOWN,
      };
    });

    return responsibleUsers.sort((a, b) => {
      return RESPONSIBLE_USER_STATUS_ORDER[a.responsibleStatus] - RESPONSIBLE_USER_STATUS_ORDER[b.responsibleStatus];
    });
  }

  sortRolesByPriority(relatedRoles) {
    const sortRoles = Vue.prototype.$lDict('client.company_roles', { attr: 'sort' });
    return _.cloneDeep(relatedRoles)
      .sort((a, b) => {
        const aIndex = sortRoles.indexOf(a.company_role_id);
        const bIndex = sortRoles.indexOf(b.company_role_id);

        if (aIndex < bIndex) {
          return -1;
        }
        if (aIndex > bIndex) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => (a.main && b.main ? 0 : a.main ? -1 : 1));
  }

  getEntityUsers() {
    const { statusKey, statusNewId } = this.selfClass;
    const users = this.getValue('users', []);

    return users.filter(
      (clientUser) =>
        (clientUser.user_id !== store.getters['Account/userId'] && clientUser[statusKey] !== statusNewId) ||
        clientUser.user_id === store.getters['Account/userId'],
    );
  }

  getClientStatuses() {
    const { statusKey, getStatuses, getUnknownStatus } = this.selfClass;
    const entityStatuses = getStatuses({ showAll: true });
    const users = store.getters['UserClient/users'] || [];
    const entityUsers = this.getEntityUsers();
    const res = [];

    entityUsers.forEach((clientStatus) => {
      const contactData = users.find((el) => el.id === clientStatus.user_id);
      const statusData = entityStatuses.find((el) => el.id === clientStatus[statusKey]) || getUnknownStatus();

      if (contactData && statusData) {
        res.push({
          userId: contactData.id,
          userName: contactData.name || contactData.email,
          statusName: statusData.name,
          statusIcon: contactData.isCurrentUser ? statusData.boldIcon : statusData.coworkerIcon,
          isCurrentUser: contactData.isCurrentUser,
          status: statusData,
        });
      }
    });

    return res;
  }

  async loadNotes() {
    const query = {
      filter: {
        entity_type_id: {
          eq: this.selfClass.entityTypeId,
        },
        entity_id: {
          eq: this.getValue('id'),
        },
      },
      sort: {
        modified_at: {
          order: 'd',
        },
      },
    };
    let res = [];

    try {
      const response = await getNotes(query);

      if (response && response.data) {
        res = response.data;
      }
    } catch (error) {}

    return new Promise((resolve) => resolve(res));
  }

  async loadActivities() {
    const query = {
      filter: {
        entity_type_id: {
          eq: this.selfClass.entityTypeId,
        },
        entity_id: {
          eq: this.getValue('id'),
        },
      },
      utc_offset: store.getters['Account/getSettingsValue']('user.utc_offset'),
    };
    let res = [];

    try {
      const response = await getActivities(query);

      if (response && response.data) {
        res = response.data;
      }
    } catch (error) {}

    return new Promise((resolve) => resolve(res));
  }

  getASalesLink() {
    const userClientId = store.getters['Account/getSettingsValue']('client.id');
    const clientIds =
      window.envMode === ENV_MODES.PRODUCTION
        ? [
            1664, // Ahlsell API account
            1857, // Ahlsell Sverige AB HK
            2034, // Ahlsell Oy
            3082, // Ahlsell Norge AS
          ]
        : [
            37603, // Ahlsell API Dev account
            37998, // Ahlsell Sverige AB HK
            38542, // Ahlsell Norge AS
          ];
    let res = null;

    if (!clientIds.includes(userClientId)) {
      return res;
    }

    const { statusKey } = this.selfClass;
    const statusId = this.getStatusValue();
    const clientStatuses = this.getValue('client_statuses', []);
    const accessStatuses = [USER_PROJECT_STATUSES.WORKING_WITH];
    const isStatusAccess =
      clientStatuses.some((el) => accessStatuses.includes(el[statusKey])) || accessStatuses.includes(statusId);

    if (isStatusAccess) {
      res =
        window.envMode === ENV_MODES.PRODUCTION
          ? `https://asales.ahlsell.com/sweetportal/#SmartProjectId=${this.getValue('id')}`
          : `https://asalestest.ahlsell.com/sweetportal/#SmartProjectId=${this.getValue('id')}`;
    }

    return res;
  }

  getFieldTags() {
    const tags = store.getters['UserTags/getUserTags'];

    if (!tags) {
      return null;
    }

    const itemTags = this.data.tags ? _.uniq(this.data.tags) : [];
    const res = [];

    itemTags.forEach((tagId) => {
      const tag = tags.findById(tagId);

      if (tag) {
        res.push(tag);
      }
    });

    return res.length > 0 ? res : null;
  }

  getExternalLinks(links = []) {
    return links.map((link) => {
      let linkText = link.title;

      if (!linkText) {
        try {
          const url = new URL(link.url);
          linkText = url.host;
        } catch (e) {
          console.log(e);
        }
      }

      return {
        ...link,
        text: linkText || link.url,
      };
    });
  }

  getCustomFieldValue(customFieldId) {
    const customFields = this.data.custom_fields || {};
    return customFields[`CF_${customFieldId}`];
  }

  updateCustomFieldsValues(customFieldsValues = {}) {
    if (!this.data.custom_fields) {
      return;
    }

    Object.entries(customFieldsValues).forEach(([key, value]) => {
      Vue.set(this.data.custom_fields, key, value);
    });
  }

  static getFormattedBudgetValue(value, showCurrency = false) {
    return showCurrency ? formatRangeCost(value) : formatNumber(value);
  }

  getBudgetValue() {
    const shouldRoundValue =
      this.getValue('original_currency_id') !== store.getters['Account/getSettingsValue']('user.currency_id');
    const value = this.getValue('value');

    return shouldRoundValue ? roundNumber(value) : value;
  }

  getFormattedBudgetValue(isShowCurrency = false) {
    const budgetValue = this.getBudgetValue();
    return this.constructor.getFormattedBudgetValue(budgetValue, isShowCurrency);
  }

  async reqUpdateStatus(value, viewed = false) {
    const query = {
      [this.selfClass.statusKey]: value,
    };

    if (viewed) {
      query.viewed = 1;
    }

    const res = await this.selfClass.updateStatusFunc(this.data.id, query);

    if (res) {
      await this.updateStatus(value);
    }
  }

  async reqPutViewed() {
    const users = this.getValue('users', []);
    const currentUser = users.find((user) => user.user_id === store.getters['Account/userId']);

    if (!currentUser || currentUser.viewed) {
      return;
    }

    const { putViewedFunc, hasContentStats } = this.selfClass;

    try {
      const response = await putViewedFunc(this.id);

      if (response && hasContentStats) {
        this.selfClass.fetchUserSectionContentStats();
      }
    } catch (error) {}
  }

  isShowCustomFields() {
    const entityUsers = this.getEntityUsers();
    return entityUsers.length > 0 && this.getValue('stored');
  }

  canChangeStatus() {
    const statusValue = this.getStatusValue();
    return !this.isLocked() || !!statusValue;
  }

  async fetchResponsibleUsers() {
    const unknownUserIds = store.getters['UserClient/getUnknownUserIds'](this.getValue('responsible_ids', []));
    const users = [];

    if (unknownUserIds.length) {
      try {
        const response = await getUsers({
          filter: {
            id: { in: unknownUserIds },
          },
        });

        if (response?.data?.length) {
          users.push(...response.data);
        }
      } catch (e) {}
    }

    return this.getResponsibles(users);
  }

  getResponsiblesPrintValue(responsibles = []) {
    return responsibles
      .map((user) => {
        const name = user.name || user.email;
        let tag = '';

        if (user.responsibleStatus === RESPONSIBLE_USER_STATUS.INACTIVE) {
          tag = Vue.prototype.$vDict('entities.responsible_inactive_user_label.text');
        }

        return tag ? `${name} ${tag}` : name;
      })
      .join('; ');
  }
}
