import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SUPPORT_SUBSCRIPTION_EDIT = {
  name: 'SupportSubscriptionEdit',
  vocabularies: ['clients', 'subscriptions', 'smart_selections', 'system_modules'],
};

export default [
  {
    path: 'subscriptions',
    redirect: '/support/subscriptions',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'all_subscriptions',
          middleware: [validateComponent],
          breadcrumb: {
            name: 'SupportSubscriptions',
          },
          dict: {
            name: 'SupportSubscriptions',
            vocabularies: ['subscriptions', 'search', 'entity_list', 'users'],
          },
          menuId: 92,
        },
        path: '/support/subscriptions',
        name: 'SupportSubscriptions',
        component: () =>
          import(/* webpackChunkName: "support-release-notes" */ '@/views/support/subscriptions/SubscriptionsList.vue'),
      },
      {
        meta: {
          breadcrumb: { name: 'Create Subscription', dynamic: true },
          component: 'all_subscriptions',
          middleware: [validateComponent],
          dict: DICTIONARY_SUPPORT_SUBSCRIPTION_EDIT,
          parentMenuId: 92,
        },
        path: 'new',
        name: 'SupportSubscriptionCreate',
        component: () =>
          import(/* webpackChunkName: "support-release-notes" */ '@/views/support/subscriptions/SubscriptionEditor'),
      },
      {
        meta: {
          breadcrumb: { name: 'Subscription', dynamic: true },
          component: 'all_subscriptions',
          middleware: [validateComponent],
          dict: {
            name: 'SupportSubscription',
            vocabularies: [
              'subscriptions',
              'clients',
              'users',
              'smart_selections',
              'search',
              'entity_list',
              'system_modules',
            ],
          },
          parentMenuId: 92,
        },
        path: ':id',
        name: 'SupportSubscription',
        component: () =>
          import(/* webpackChunkName: "support-release-notes" */ '@/views/support/subscriptions/Subscription'),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit Subscription', dynamic: true },
          component: 'all_subscriptions',
          middleware: [validateComponent],
          dict: DICTIONARY_SUPPORT_SUBSCRIPTION_EDIT,
          parentMenuId: 92,
        },
        path: ':id/edit',
        name: 'SupportSubscriptionEdit',
        component: () =>
          import(/* webpackChunkName: "support-release-notes" */ '@/views/support/subscriptions/SubscriptionEditor'),
      },
    ],
  },
];
