import _, { isArray } from 'lodash';
import { CookiesStorage } from '@/shared/storage/drivers/cookiesStorage';
import store from '@/store';
import Vue from 'vue';
import vars from '@/config/vars';

const { DateTime } = require('luxon');

export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function replaceEnvPath(str, mapObj) {
  const newObject = _.mapKeys(mapObj, (value, key) => `{${key}}`);
  const re = new RegExp(Object.keys(newObject).join('|'), 'gi');
  return str.replace(re, (matched) => newObject[matched.toLowerCase()]);
}

export function dataFindItem(data, key) {
  return data.find((item) => item === key);
}

export function getIdsFromArray(items, _options = {}) {
  const { idKey = 'id', filterFunc = undefined, isParentId = false, childrenKey = 'items' } = _options;
  let res = [];

  items.forEach((item) => {
    if (filterFunc === undefined || filterFunc(item)) {
      const childrens = item[childrenKey] || [];

      if (isParentId) {
        res.push(item[idKey]);
      }
      if (childrens.length > 0) {
        const itemIds = getIdsFromArray(childrens, _options);
        res = res.concat(itemIds);
      } else {
        res.push(item[idKey]);
      }
    }
  });

  return res;
}

export function findById(items, id, idKey = 'id', itemsKey = 'items') {
  let res = null;

  if (Array.isArray(id)) {
    res = [];

    items.forEach((item) => {
      const children = item[itemsKey] || [];

      if (id.includes(item[idKey])) {
        res.push(item);
      } else if (children.length > 0) {
        res = _.concat(res, findById(children, id, idKey));
      }
    });
  } else {
    for (let i = 0; i < items.length && !res; i++) {
      const item = items[i];
      const children = item[itemsKey] || [];

      if (item[idKey] === id) {
        res = item;
      } else if (children.length > 0) {
        res = findById(children, id, idKey);
      }
    }
  }

  return res;
}

export function filterItems(items, options = {}) {
  const config = _.defaults({}, options, {
    ids: null,
    idKey: 'id',
    filterFunc: null,
    parentRequired: false,
    previousFilterResult: null,
    childrenKey: 'items',
  });

  return _.cloneDeep(items).filter((item) => {
    const itemId = item[config.idKey];
    const children = item[config.childrenKey] || [];
    let isAccessed = true;
    let filterResult = null;

    if (config.ids) {
      isAccessed = config.ids.includes(itemId);
    }

    if (isAccessed && config.filterFunc) {
      filterResult = config.filterFunc(item, config.previousFilterResult);
      isAccessed = isEmptyValue(filterResult) ? false : !!filterResult;
    }

    if (children.length > 0 && (config.parentRequired ? isAccessed : !isAccessed)) {
      item[config.childrenKey] = filterItems(children, {
        ...config,
        previousFilterResult: filterResult,
      });
      isAccessed = item[config.childrenKey].length > 0;
    }

    return isAccessed;
  });
}

export function getItemsId(items = [], key = 'id', filter = null) {
  let res = items || [];

  if (filter) {
    res = res.filter((item) => Object.keys(filter).every((k) => item[k] === filter[k]));
  }

  res = res.map((el) => el[key]).filter((el) => el);

  return _.uniq(res);
}

export function removeDuplicates(array, key) {
  return array.filter((obj, index, self) => index === self.findIndex((el) => el[key] === obj[key]));
}

export function getGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function isEmptyValue(value, includeValues = []) {
  const typeValue = typeof value;
  let empty = !value;

  if (Array.isArray(includeValues) && includeValues.includes(value)) {
    empty = false;
  } else {
    switch (typeValue) {
      case 'object':
        empty = _.isEmpty(value);

        if (value instanceof Date) {
          empty = false;
        } else if (!empty && (_.has(value, 'gte') || _.has(value, 'lte'))) {
          empty = isEmptyValue(value.gte) && isEmptyValue(value.lte);
        }
        break;

      case 'boolean':
      case 'number':
        empty = false;
        break;

      case 'string':
        empty = value === '';
        break;

      default:
    }
  }

  return empty;
}

export function strToOrgNr(str) {
  const regex = new RegExp(/^(\d{6,})(-)/);
  const regexNor = new RegExp(/^(\d{3})\s(\d{1,3})\s?(\d+)?/);
  let resStr = str;
  if (regex.test(str)) {
    resStr = str.replace(regex, '$1');
  }
  if (regexNor.test(str)) {
    resStr = str.replace(regexNor, '$1$2$3');
  }
  return resStr;
}

/**
 * @return {array}
 */
export function convertObjectToArray(items) {
  const res = !_.isEmpty(items) ? Object.values(items) : [];

  res.forEach((item) => {
    if (!Array.isArray(item.items)) {
      item.items = convertObjectToArray(item.items);
    }
  });

  return res;
}

function isExternalLink(url) {
  const reg = new RegExp(/(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/);
  return reg.test(url);
}

export function getExternalLink(url) {
  return isExternalLink(url) ? url : `http://${url}`;
}

export function updateObjectById(items, id, props) {
  let item = items.find((el) => el.id === id);

  if (item) {
    Object.assign(item, props);
  }
}

export function isEqual(a, b) {
  let res;

  if (typeof a === 'object' && typeof b === 'object') {
    res = _.isEqual(a, b);
  } else {
    res = a === b;
  }

  return res;
}

export function absArray(arr) {
  let res = arr;
  if (Array.isArray(arr)) {
    res = arr.map((el) => (el < 0 ? el * -1 : el));
  }
  return res;
}

export function visibilityObserver(element, callback, _options = {}) {
  const options = {
    root: document.documentElement,
    threshold: 0.25,
    ..._options,
  };

  const resObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      callback(entry.intersectionRatio > 0, observer);
    });
  }, options);

  resObserver.observe(element);

  return resObserver;
}

export function debugLog(msg = '') {
  const isDebugLogs = CookiesStorage.get('DEBUG_LOGS');

  if (isDebugLogs) {
    if (Array.isArray(msg)) {
      console.log(...msg);
    } else {
      console.log(msg);
    }
  }
}

export function rmEmptyParams(data = {}) {
  const res = {};

  Object.keys(data).forEach((key) => {
    if (!isEmptyValue(data[key])) {
      res[key] = data[key];
    }
  });

  return res;
}

export function downloadFile(file) {
  const fileType = isArray(file.content_type) ? file.content_type.split(';')[0] : file.content_type;
  const fileContentBase64 = file.content;
  const fileName = file.filename;
  const linkSource = `data:${fileType};base64,${fileContentBase64}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function upLevelIds(items, ids, idKey = 'id') {
  let res = [];

  items.forEach((item) => {
    const children = item.items || [];
    const isItemIncludes = ids.includes(item[idKey]);

    if (children.length > 0 && isItemIncludes) {
      res = _.union(res, [item[idKey]]);
    } else if (children.length > 0) {
      const isEvery = children.every((el) => ids.includes(el[idKey]));

      if (isEvery) {
        res = _.union(res, [item[idKey]]);
      } else {
        res = _.union(res, upLevelIds(children, ids, idKey));
      }
    } else if (isItemIncludes) {
      res = _.union(res, [item[idKey]]);
    }
  });

  return res;
}

export function upLevelIdsDeep(items, ids, idKey = 'id') {
  let res = [];
  let resUp = upLevelIds(items, ids, idKey);

  while (!_.isEqual(res, resUp)) {
    res = _.cloneDeep(resUp);
    resUp = upLevelIds(items, res, idKey);
  }

  return res;
}

export function downLevelIds(items, ids, idKey = 'id') {
  let res = [];

  items.forEach((item) => {
    const children = item.items || [];
    const isItemIncludes = ids.includes(item[idKey]);

    if (children.length > 0 && isItemIncludes) {
      res = _.union(
        res,
        downLevelIds(
          children,
          children.map((el) => el[idKey]),
          idKey,
        ),
      );
    } else if (children.length > 0) {
      res = _.union(res, downLevelIds(children, ids, idKey));
    } else if (isItemIncludes) {
      res = _.union(res, [item[idKey]]);
    }
  });

  return res;
}

export function getItemsMaxId(items, idKey = 'id') {
  return items.reduce((a, b) => Math.max(a, b[idKey]), 0);
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function scrollIntoCustomView(element, inputConfig = {}) {
  const defaultConfig = {
    behavior: 'smooth',
    block: 'end',
  };

  const config = _.defaults({}, inputConfig, defaultConfig);

  element.scrollIntoView({
    ...config,
  });
}

export function scrollToElement(element, inputConfig = {}) {
  const defaultConfig = {
    topOffset: 16,
    scrollParent: window,
    relativeDocument: false,
  };
  const config = _.defaults({}, inputConfig, defaultConfig);
  let offsetPosition = element.offsetTop;

  if (config.relativeDocument) {
    const rect = element.getBoundingClientRect(),
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    offsetPosition = rect.top + scrollTop;
  }

  offsetPosition -= config.topOffset;

  if (config.scrollParent) {
    config.scrollParent.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}

export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export function convertArrayToObject(array, key) {
  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    {},
  );
}

/**
 * Get HTML asynchronously
 * @param  {String}   url      The URL to get HTML from
 * @param  {Function} callback A callback funtion. Pass in "response" variable to use returned HTML.
 */
export function getHtml(url) {
  return new Promise((resolve, reject) => {
    // Feature detection
    if (!window.XMLHttpRequest) return;

    // Create new request
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      resolve(xhr.responseXML);
    };

    xhr.onerror = () => {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };

    // Get the HTML
    xhr.open('GET', url);
    xhr.responseType = 'document';
    xhr.send();
  });
}

export function getSearchRegex(text, fromStart = false) {
  return new RegExp(`${fromStart ? '(^)' : ''}(${_.escapeRegExp(text)})`, 'gi');
}

export function parseDateTime(date, _opts = {}) {
  const opts = _.defaults({}, _opts, {
    parseFrom: 'fromISO',
    parseFormat: {},
    userZone: false,
    utc: false,
  });
  let resDate = opts.utc ? DateTime.utc() : DateTime.now();

  if (date?.isLuxonDateTime) {
    resDate = date;
  } else if (date) {
    resDate = DateTime[opts.parseFrom](date, opts.parseFormat);
  }

  if (opts.userZone) {
    resDate = resDate.setZone(store.getters['Account/getSettingsValue']('user.timezone'));
  } else if (opts.utc) {
    resDate = resDate.toUTC();
  }

  return resDate.setLocale(Vue.prototype.$getDictLanguage('intlIso'));
}

export function formatDateTime(date, _opts = {}) {
  const opts = _.defaults({}, _opts, {
    toFormat: vars.LUXON_FORMAT_SHORT_DATE,
  });

  return parseDateTime(date, _opts).toFormat(opts.toFormat);
}

export function formatDateTimeNoWrap(valueDate, _opts = {}) {
  const opts = _.defaults({}, _opts, {
    userZone: false,
    toFormat: vars.LUXON_FORMAT_SHORT_DATE,
  });
  let res = '';

  if (valueDate) {
    const regex = /^(.*?) ([Hh]{2}:[Mm]{2}(:[Ss]{2})?)$/;
    const arr = regex.exec(opts.toFormat);
    const date = parseDateTime(valueDate, _opts);

    if (arr && arr[1] && arr[2]) {
      const formats = _.compact([arr[1], arr[2]]);

      res = formats
        .map((el, i) => {
          let item = date.toFormat(el);

          if (i === 0) {
            item = item.replaceAll(' ', '\u00A0');
          }

          return item;
        })
        .join(' ');
    } else {
      const textDate = date.toFormat(opts.toFormat);
      res = textDate.replaceAll(' ', '\u00A0');
    }
  }

  return res;
}

export function getDateTextOffsetToday(dateIso) {
  const date = dateIso ? DateTime.fromISO(dateIso) : DateTime.now();
  const diffVariants = [
    {
      key: 'years',
      text: 'text_amount_years_ago',
      oneText: 'text_year_ago',
    },
    {
      key: 'months',
      text: 'text_amount_months_ago',
      oneText: 'text_month_ago',
    },
    {
      key: 'weeks',
      text: 'text_amount_weeks_ago',
      oneText: 'text_week_ago',
    },
    {
      key: 'days',
      text: 'text_amount_days_ago',
      emptyText: 'text_today',
      oneText: 'text_yesterday',
    },
  ];
  let i = 0;
  let res = '';

  while (i < diffVariants.length && !res) {
    const diffVariant = diffVariants[i];
    const diffValue = DateTime.now().diff(date, diffVariant.key).toObject();
    const diffCount = parseInt(diffValue[diffVariant.key]) ?? 0;

    if (diffCount > 0) {
      res =
        diffCount === 1
          ? Vue.prototype.$vDict(`global.${diffVariant.oneText}.text`)
          : Vue.prototype.$vDict(`global.${diffVariant.text}.text`, { amount: diffCount });
    } else if (diffVariant.emptyText) {
      res = Vue.prototype.$vDict(`global.${diffVariant.emptyText}.text`);
    }

    i++;
  }

  return res;
}

export function parseValueFromString(str) {
  let res = isNaN(str) ? str : parseInt(str);

  if (typeof res === 'string' && res[0] === "'" && res[res.length - 1] === "'") {
    res = res.substring(1, res.length - 1);
  }

  return res;
}

export function parseFilterFromUrlQuery(urlQuery) {
  const filters = urlQuery.split(',');
  const res = {};

  filters.forEach((filter) => {
    const filterParts = filter.split(':');

    if (filterParts.length > 2) {
      let filterValue = parseValueFromString(filterParts[2]);

      if (
        (filterParts[1] === 'gteOffsetDays' || filterParts[1] === 'lteOffsetDays') &&
        typeof filterValue === 'string'
      ) {
        const date = DateTime.fromISO(filterValue);

        if (date?.isLuxonDateTime && !date?.invalid) {
          const now = DateTime.now().set({
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });

          filterValue = date.diff(now, 'days').toObject().days;
        } else {
          filterValue = null;
        }
      }

      if (!isEmptyValue(filterValue)) {
        res[filterParts[0]] = {
          ...(res[filterParts[0]] || {}),
          [filterParts[1]]: filterValue,
        };
      }
    } else if (filterParts.length > 1) {
      let filterValue = parseValueFromString(filterParts[1]);

      if (typeof filterValue === 'string' && _.first(filterValue) === '[' && _.last(filterValue) === ']') {
        const arrayString = filterValue.substring(1, filterValue.length - 1);
        const arrayValues = arrayString.split(';');

        filterValue = arrayValues.map((val) => parseValueFromString(val));
      }

      res[filterParts[0]] = filterValue;
    }
  });

  return res;
}

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setElementPositionNear(target, element, right = false) {
  if (!target || !element) return;

  const yOffset = 8;
  const paddings = 10;
  const fvBtnRect = target.getBoundingClientRect();
  const fvPopupRect = element.getBoundingClientRect();

  const fvBtnTop = fvBtnRect.top + window.pageYOffset;

  const left = (right ? fvBtnRect.left + fvBtnRect.width - fvPopupRect.width : fvBtnRect.left) + window.pageXOffset;
  let offsetLeft = 0;
  let top = fvBtnTop + fvBtnRect.height + yOffset;

  if (fvBtnRect.top + fvBtnRect.height + yOffset + fvPopupRect.height + paddings >= window.innerHeight) {
    top = fvBtnTop - fvPopupRect.height - yOffset;
  }

  const rightOverflow = left + fvPopupRect.width + paddings;
  const leftOverflow = left - fvPopupRect.width - paddings;

  if (rightOverflow > document.body.clientWidth) {
    offsetLeft = document.body.clientWidth - rightOverflow;
  } else if (right && leftOverflow < 0) {
    offsetLeft = Math.abs(leftOverflow);
  } else {
    offsetLeft = 0;
  }

  element.style.top = `${top}px`;
  element.style.left = `${left}px`;
  element.style.marginLeft = offsetLeft ? `${offsetLeft}px` : null;
}

export function convertStringToKey(str = '') {
  return str.toLowerCase().replace(/ /g, '_');
}

export function createLinkAndClick(linkAttributes = {}) {
  const link = document.createElement('a');
  const guid = getGuid();
  const linkId = `redirect-url-${guid}`;

  Object.assign(link, linkAttributes, { id: linkId });

  document.body.prepend(link);
  link.click();
  link.remove();
}

export function convertOffsetsToDateRange(offsets = {}, def = null) {
  let res = {};

  if (!offsets) {
    return res;
  }

  const now = DateTime.utc();

  if (!isEmptyValue(offsets.gteOffsetMonths) || !isEmptyValue(offsets.lteOffsetMonths)) {
    res = {
      gte: !isEmptyValue(offsets.gteOffsetMonths)
        ? now.plus({ months: offsets.gteOffsetMonths }).startOf('month')
        : def,
      lte: !isEmptyValue(offsets.lteOffsetMonths) ? now.plus({ months: offsets.lteOffsetMonths }).endOf('month') : def,
    };
  } else if (!isEmptyValue(offsets.gteOffsetDays) || !isEmptyValue(offsets.lteOffsetDays)) {
    res = {
      gte: !isEmptyValue(offsets.gteOffsetDays) ? now.plus({ days: offsets.gteOffsetDays }).startOf('day') : def,
      lte: !isEmptyValue(offsets.lteOffsetDays) ? now.plus({ days: offsets.lteOffsetDays }).endOf('day') : def,
    };
  }

  return res;
}

export function convertDateRangeToOffsets(dateRange = {}, periodType = 'days') {
  const startOfCurrentDay = DateTime.utc().startOf('day');
  let res = {};

  if (!dateRange?.gte && !dateRange?.lte) {
    return res;
  }

  if (periodType === 'days') {
    res = {
      gteOffsetDays: dateRange?.gte
        ? dateRange.gte.startOf('day').diff(startOfCurrentDay, 'days').toObject().days
        : null,
      lteOffsetDays: dateRange?.lte
        ? dateRange.lte.startOf('day').diff(startOfCurrentDay, 'days').toObject().days
        : null,
    };
  } else if (periodType === 'months') {
    res = {
      gteOffsetMonths: dateRange?.gte
        ? Math.ceil(dateRange.gte.startOf('months').diff(startOfCurrentDay, 'months').toObject().months)
        : null,
      lteOffsetMonths: dateRange?.lte
        ? Math.ceil(dateRange.lte.startOf('months').diff(startOfCurrentDay, 'months').toObject().months)
        : null,
    };
  }

  return res;
}

export function convertWeekRangeToDateRange(weekRange = {}) {
  const res = {
    gte: '',
    lte: '',
  };

  Object.keys(weekRange).forEach((optKey) => {
    const opt = weekRange[optKey];

    if (opt) {
      const optParts = opt.split('-');
      const dateType = optParts[0];
      const dateValue = optParts[1];
      let dt = DateTime.now();

      switch (dateType) {
        case 'w':
          dt = dt.plus({ weeks: -dateValue });

          if (optKey === 'gteOffsetWeeks') {
            res.gte = dt.startOf('week');
          } else {
            res.lte = dt.endOf('week');
          }
          break;

        case 'd':
          if (dateValue < dt.weekday) {
            dt = dt.set({ weekday: dateValue });
          }

          if (optKey === 'gteOffsetWeeks') {
            res.gte = dt.startOf('day');
          } else {
            res.lte = dt.endOf('day');
          }
          break;

        case 'today':
          if (optKey === 'gteOffsetWeeks') {
            res.gte = dt.startOf('day');
          } else {
            res.lte = dt.endOf('day');
          }
          break;

        default:
      }
    }
  });

  return res;
}

export function isNumber(number) {
  return typeof number === 'number';
}

export function mergeQueryParams(...objects) {
  return objects.reduce((acum, object) => {
    return _.mergeWith(acum, object, (objValue, srcValue, key, object, source, stack) => {
      if (stack.size > 0 || _.isArray(objValue)) {
        return srcValue;
      }
    });
  }, {});
}

export function getMonthsLocale(localeName = 'en', month = 'long') {
  const format = new Intl.DateTimeFormat(localeName, { month }).format;
  return [...Array(12).keys()].map((m) => format(new Date(Date.UTC(2021, (m + 1) % 12))));
}

export function getWeekdaysLocale(localeName = 'en', weekday = 'long') {
  const format = new Intl.DateTimeFormat(localeName, { weekday }).format;
  return [...Array(7).keys()].map((day) => format(new Date(Date.UTC(2021, 1, day + 1))));
}

export function parseIntFromString(str) {
  const number = parseInt(str);
  return !isNaN(number) ? number : null;
}

export async function multipleLoadItems(loadFunc, limit) {
  const response = await loadFunc();
  const itemsTotal = response?.meta?.total || 0;
  let items = response?.data || [];

  if (itemsTotal <= limit) {
    return items;
  }

  const amountOfRequests = itemsTotal / limit - 1;
  const requests = [];

  for (let i = 0; i < amountOfRequests; i++) {
    const offset = (i + 1) * limit;
    requests.push(loadFunc(offset, limit));
  }

  const responses = await Promise.all(requests);

  responses.forEach((res) => {
    const resItems = res?.data || [];
    items = items.concat(resItems);
  });

  return items;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getExponent(value) {
  let exp = 0;
  if (Math.abs(Number(value)) >= 1.0e9) {
    exp = 9;
  } else if (Math.abs(Number(value)) >= 1.0e6) {
    exp = 6;
  } else if (Math.abs(Number(value)) >= 1.0e3) {
    exp = 3;
  }
  return exp;
}

/* Round to the required number of decimal places and fix Math.round bug */
export function fixRound(value, decimals) {
  const tVal = Math.round(Number(`${value}e${decimals}`));
  return Number(`${tVal}e-${decimals}`);
}

/* Get a string from a number with the required fixed number of decimal places */
export function toFixed(value, decimals) {
  return fixRound(value, decimals).toFixed(decimals);
}

export function roundNumber(number) {
  return typeof number === 'number' ? Number(number.toPrecision(2)) : undefined;
}
