import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

const DICTIONARY_PARABYG_MIGRATION = {
  name: 'ParabygMigration',
  vocabularies: ['parabyg_migration', 'entity_list'],
};

export default [
  dashboardContainer({
    meta: {
      breadcrumb: { name: 'Parabyg migration' },
      menuId: 78,
    },
    path: '/parabyg-migration',
    redirect: '/parabyg-migration/clients',
    name: 'ParabygMigration',
    children: [
      {
        path: 'clients',
        redirect: '/parabyg-migration/clients',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'parabyg_migration',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Clients',
              },
              dict: DICTIONARY_PARABYG_MIGRATION,
              menuId: 79,
            },
            path: '/parabyg-migration/clients',
            name: 'PMClients',
            component: () =>
              import(/* webpackChunkName: "pm-clients" */ '@/views/parabyg-migration/clients/ClientsList'),
          },
          {
            meta: {
              breadcrumb: { name: 'Create client' },
              component: 'parabyg_migration',
              middleware: [validateComponent],
              dict: DICTIONARY_PARABYG_MIGRATION,
              parentMenuId: 79,
            },
            path: 'new',
            name: 'PMClientCreate',
            component: () =>
              import(/* webpackChunkName: "pm-client-editor" */ '@/views/parabyg-migration/clients/ClientEditor'),
          },
        ],
      },
      {
        path: 'client-versions',
        redirect: '/parabyg-migration/client-versions',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'parabyg_migration',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Client versions',
              },
              dict: DICTIONARY_PARABYG_MIGRATION,
              menuId: 80,
            },
            path: '/parabyg-migration/client-versions',
            name: 'PMClientVersions',
            component: () =>
              import(
                /* webpackChunkName: "pm-client-versions" */ '@/views/parabyg-migration/client-versions/ClientVersionsList'
              ),
          },
          {
            meta: {
              breadcrumb: { name: 'Create client version' },
              component: 'parabyg_migration',
              middleware: [validateComponent],
              dict: DICTIONARY_PARABYG_MIGRATION,
              parentMenuId: 80,
            },
            path: 'new',
            name: 'PMClientVersionCreate',
            component: () =>
              import(
                /* webpackChunkName: "pm-client-version-editor" */ '@/views/parabyg-migration/client-versions/ClientVersionEditor'
              ),
          },
        ],
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Projects' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 81,
        },
        path: '/parabyg-migration/projects',
        name: 'PMProjects',
        component: () =>
          import(/* webpackChunkName: "pm-projects" */ '@/views/parabyg-migration/projects/ProjectsList'),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Companies' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 82,
        },
        path: '/parabyg-migration/companies',
        name: 'PMCompanies',
        component: () =>
          import(/* webpackChunkName: "pm-companies" */ '@/views/parabyg-migration/companies/CompaniesList'),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Contacts' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 83,
        },
        path: '/parabyg-migration/contacts',
        name: 'PMContacts',
        component: () =>
          import(/* webpackChunkName: "pm-contacts" */ '@/views/parabyg-migration/contacts/ContactsList'),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Employees' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 84,
        },
        path: '/parabyg-migration/employees',
        name: 'PMEmployees',
        component: () =>
          import(/* webpackChunkName: "pm-employees" */ '@/views/parabyg-migration/employees/EmployeesList'),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Documents' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 85,
        },
        path: '/parabyg-migration/documents',
        name: 'PMDocuments',
        component: () =>
          import(/* webpackChunkName: "pm-documents" */ '@/views/parabyg-migration/documents/DocumentsList'),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Activities' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 86,
        },
        path: '/parabyg-migration/activities',
        name: 'PMActivities',
        component: () =>
          import(/* webpackChunkName: "pm-activities" */ '@/views/parabyg-migration/activities/ActivitiesList'),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom fields' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 87,
        },
        path: '/parabyg-migration/custom-fields',
        name: 'PMCustomFields',
        component: () =>
          import(/* webpackChunkName: "pm-custom-fields" */ '@/views/parabyg-migration/custom-fields/CustomFieldsList'),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom field values' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 88,
        },
        path: '/parabyg-migration/custom-field-values',
        name: 'PMCustomFieldValues',
        component: () =>
          import(
            /* webpackChunkName: "pm-custom-field-values" */ '@/views/parabyg-migration/custom-fields/CustomFieldValuesList'
          ),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom field selections' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 89,
        },
        path: '/parabyg-migration/custom-field-selections',
        name: 'PMCustomFieldSelections',
        component: () =>
          import(
            /* webpackChunkName: "pm-custom-field-selections" */ '@/views/parabyg-migration/custom-fields/CustomFieldSelectionsList'
          ),
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom field employees' },
          dict: DICTIONARY_PARABYG_MIGRATION,
          menuId: 90,
        },
        path: '/parabyg-migration/custom-field-employees',
        name: 'PMCustomFieldEmployees',
        component: () =>
          import(
            /* webpackChunkName: "pm-custom-field-employees" */ '@/views/parabyg-migration/custom-fields/CustomFieldEmployeesList'
          ),
      },
    ],
  }),
];
