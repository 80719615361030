import validateComponent from '@/router/middleware/validateComponent';

export default [
  {
    path: 'error-messages',
    redirect: '/system/error-messages',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Error messages' },
          component: 'error_messages_list',
          middleware: [validateComponent],
          menuId: 91,
          dict: {
            name: 'SystemErrorMessages',
            vocabularies: ['entity_list', 'error_messages'],
          },
        },
        path: '/system/error-messages',
        name: 'SystemErrorMessages',
        component: () =>
          import(
            /* webpackChunkName: "error-messages" */ '@/views/system/error-messages/ErrorMessagesList'
          ),
      },
    ],
  },
];
