import Vue from 'vue';
import vars from '@/config/vars';
import store from '@/store';
import { formatNumber, getCurrencySymbol } from './number';
import { formatCost } from './number-cost';

export function formatBudgetValue(val, min, max, currencyId) {
  const maxValue = max || vars.CONST_MAX_BUDGET_LIMIT;
  const minValue = min || 0;
  const formatConfig = {};
  let value;
  let res = '';

  if (typeof val !== 'number') {
    value = 0;
  } else {
    value = val < minValue ? minValue : val > maxValue ? maxValue : val;
  }

  if (currencyId) {
    res = formatCost(value, { ...formatConfig, currencyId });
  } else {
    res = formatNumber(value, formatConfig);
  }

  if (value === vars.CONST_MAX_BUDGET_LIMIT) {
    res += '+';
  }

  return res;
}

function getBudgetRange(values, options = {}) {
  const opts = {
    min: null,
    max: null,
    currencyId: store.getters['Account/getSettingsValue']('user.currency_id'),
    ...options,
  };
  const budgetRange = values || [];

  return {
    from: formatBudgetValue(budgetRange[0] ?? opts.min, opts.min, opts.max),
    to: formatBudgetValue(budgetRange[1] ?? opts.max, opts.min, opts.max),
    currencySymbol: getCurrencySymbol(opts.currencyId),
  };
}

export function formatBudgetRange(values, options = {}) {
  const budgetRange = getBudgetRange(values, options);
  const range = `${budgetRange.from} - ${budgetRange.to}`;
  const { currencySymbol } = budgetRange;

  return currencySymbol.symbolSide === 'left'
    ? `${currencySymbol.symbol} ${range}`
    : `${range} ${currencySymbol.symbol}`;
}

export function formatBudgetRangeFromTo(values, options = {}) {
  const budgetRange = getBudgetRange(values, options);
  const text_from = Vue.prototype.$vDict('global.text_from.text');
  const text_to = Vue.prototype.$vDict('global.text_to.text');
  const { currencySymbol } = budgetRange;
  const rangeText = `${budgetRange.from} ${text_to} ${budgetRange.to}`;
  const rangeTextWithSymbol =
    currencySymbol.symbolSide === 'left'
      ? `${currencySymbol.symbol} ${rangeText}`
      : `${rangeText} ${currencySymbol.symbol}`;

  return `${text_from} ${rangeTextWithSymbol}`;
}
