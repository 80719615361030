import Vue from 'vue';
import _defaults from 'lodash/defaults';
import store from '@/store';
import { formatNumberToParts, formatRangeNumber, getCurrencySymbol } from './number';

export function formatCostToParts(num, options = {}) {
  const opts = {
    round: false,
    decimal: true,
    currencyId: store.getters['Account/getSettingsValue']('user.currency_id'),
    ...options,
  };
  const currency = Vue.prototype.$lFind('global.currencies', {
    id: opts.currencyId,
  });

  return formatNumberToParts(num, {
    decimal: opts.decimal,
    round: opts.round,
    formatOptions: {
      style: 'currency',
      currency: currency?.code,
      minimumFractionDigits: 0,
    },
  });
}

export function formatCost(num, options = {}) {
  const parts = formatCostToParts(num, options);
  return parts.reduce((string, part) => string + part.value, '');
}

export function formatRangeCost(value, options) {
  const defOptions = {
    decimal: true,
    round: false,
    currencyId: store.getters['Account/getSettingsValue']('user.currency_id'),
  };
  const opts = _defaults({}, options, defOptions);
  const currencySymbol = getCurrencySymbol(opts.currencyId);
  const rangeNumber = formatRangeNumber(value, opts);
  const { symbolSide, symbol } = currencySymbol;

  return symbolSide === 'left' ? `${symbol} ${rangeNumber}` : `${rangeNumber} ${symbol}`;
}
