import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../../containers';
import clients from './clients';
import users from './users';
import releaseNotes from './release-notes';
import subscriptions from './subscriptions';

export default [
  dashboardContainer({
    meta: {
      breadcrumb: { name: 'support' },
      menuId: 40,
    },
    path: '/support',
    redirect: '/support/clients',
    name: 'Support',
    children: [
      ...clients,
      ...users,
      ...releaseNotes,
      ...subscriptions,
      {
        meta: {
          component: 'all_files',
          middleware: [validateComponent],
          dict: {
            name: 'SupportFiles',
            vocabularies: ['user_files'],
          },
          menuId: 60,
        },
        path: '/support/files',
        name: 'SupportFiles',
        component: () => import(/* webpackChunkName: "support-files" */ '@/views/support/files/Files'),
      },
    ],
  }),
];
