import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_MY_COMPANIES = {
  name: 'MyCompanies',
  vocabularies: ['company', 'companies', 'entity_list', 'triggers', 'search', 'companies', 'notes', 'activities'],
};

const DICTIONARY_COMPANY = {
  name: 'Company',
  vocabularies: [
    'entity_list',
    'company',
    'triggers',
    'project',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'fields_company_related',
    'notes',
    'activities',
    'search',
    'company_analysis',
  ],
};

export const companies = [
  {
    path: 'my-companies',
    redirect: '/sales/my-companies',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/sales/my-companies',
        name: 'MyCompanies',
        component: () => import(/* webpackChunkName: "my-companies" */ '@/views/sales/MyCompanies'),
        meta: {
          requiredQuery: {
            folder: -1,
          },
          component: 'user_companies',
          middleware: [validateComponent],
          breadcrumb: { name: 'my_companies' },
          dict: DICTIONARY_MY_COMPANIES,
          menuId: 4,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'company', dynamic: true },
          component: 'user_companies',
          middleware: [validateComponent],
          dict: DICTIONARY_COMPANY,
          parentMenuId: 4,
        },
        path: ':id',
        name: 'MyCompany',
        component: () => import(/* webpackChunkName: "company" */ '@/views/leads/Company'),
      },
    ],
  },
];

export const printCompanies = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_MY_COMPANIES,
    },
    path: '/sales/my-companies/print',
    name: 'PrintUserCompanies',
    component: () => import(/* webpackChunkName: "print-companies" */ '@/views/print/PrintCompanies'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_COMPANY,
    },
    path: '/sales/my-companies/:id/print',
    name: 'PrintUserCompany',
    component: () => import(/* webpackChunkName: "print-company" */ '@/views/print/print-pages/PagePrintCompany'),
  },
];
