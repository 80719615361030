import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_ALL_TENDERS = {
  name: 'AllTenders',
  vocabularies: ['tenders', 'entity_list', 'triggers', 'search', 'tenders', 'notes', 'activities', 'project'],
};

const DICTIONARY_TENDER = {
  name: 'Tender',
  vocabularies: [
    'entity_list',
    'tender',
    'project',
    'triggers',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'notes',
    'activities',
    'company',
    'contacts',
    'global',
  ],
};

export const tenders = [
  {
    path: 'tenders',
    redirect: '/leads/tenders',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/leads/tenders',
        name: 'Tenders',
        component: () => import(/* webpackChunkName: "all-tenders" */ '@/views/leads/AllTenders'),
        meta: {
          component: 'all_tenders',
          middleware: [validateComponent],
          breadcrumb: { name: 'all_tenders' },
          dict: DICTIONARY_ALL_TENDERS,
          menuId: 12,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'tender', dynamic: true },
          component: 'tender',
          middleware: [validateComponent],
          dict: DICTIONARY_TENDER,
          parentMenuId: 12,
        },
        path: ':id',
        name: 'Tender',
        component: () => import(/* webpackChunkName: "tender" */ '@/views/leads/Tender'),
      },
    ],
  },
];

export const printTenders = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_ALL_TENDERS,
    },
    path: '/leads/tenders/print',
    name: 'PrintTenders',
    component: () => import(/* webpackChunkName: "print-tenders" */ '@/views/print/PrintTenders'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_TENDER,
    },
    path: '/leads/tenders/:id/print',
    name: 'PrintTender',
    component: () => import(/* webpackChunkName: "print-tender" */ '@/views/print/print-pages/PagePrintTender'),
  },
];
