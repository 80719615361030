import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SUPPORT_CLIENT = {
  name: 'SupportClient',
  vocabularies: [
    'client_profile',
    'clients',
    'users',
    'project',
    'tender',
    'fixed_addons',
    'entity_list',
    'custom_fieldset',
    'search',
    'subscriptions',
    'system_modules',
  ],
};

export default [
  {
    path: 'clients',
    redirect: '/support/clients',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'all_clients',
          middleware: [validateComponent],
          breadcrumb: { name: 'Organizations' },
          dict: {
            name: 'SupportClients',
            vocabularies: ['subscriptions', 'triggers', 'search', 'client_profile', 'clients', 'users'],
          },
          menuId: 41,
        },
        path: '/support/clients',
        name: 'SupportClients',
        component: () => import(/* webpackChunkName: "support-clients" */ '@/views/support/clients/ListClients'),
      },
      {
        meta: {
          breadcrumb: { name: 'Create organization' },
          component: 'all_clients',
          middleware: [validateComponent],
          dict: DICTIONARY_SUPPORT_CLIENT,
          parentMenuId: 41,
        },
        path: 'new',
        name: 'SupportEditorClientNew',
        component: () => import(/* webpackChunkName: "support-client-editor" */ '@/views/support/clients/EditorClient'),
      },
      {
        meta: {
          breadcrumb: { name: 'Organization', dynamic: true },
          component: 'all_clients',
          middleware: [validateComponent],
          dict: DICTIONARY_SUPPORT_CLIENT,
          parentMenuId: 41,
        },
        path: ':id',
        name: 'SupportClient',
        component: () => import(/* webpackChunkName: "support-client" */ '@/views/support/clients/Client'),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit organization', dynamic: true },
          component: 'all_clients',
          middleware: [validateComponent],
          dict: {
            name: 'SupportEditorClient',
            vocabularies: ['triggers', 'search', 'client_profile', 'clients', 'system_modules'],
          },
          parentMenuId: 41,
        },
        path: ':id/edit',
        name: 'SupportEditorClient',
        component: () => import(/* webpackChunkName: "support-client-editor" */ '@/views/support/clients/EditorClient'),
      },
    ],
  },
];
