import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_MY_CONTACTS = {
  name: 'MyContacts',
  vocabularies: ['contacts', 'entity_list', 'search', 'contacts', 'notes', 'activities'],
};

const DICTIONARY_CONTACT = {
  name: 'Contact',
  vocabularies: [
    'entity_list',
    'contacts',
    'triggers',
    'project',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'notes',
    'activities',
    'search',
  ],
};

export const contacts = [
  {
    path: 'my-contacts',
    redirect: '/sales/my-contacts',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/sales/my-contacts',
        name: 'MyContacts',
        component: () => import(/* webpackChunkName: "my-contacts" */ '@/views/sales/MyContacts'),
        meta: {
          component: 'user_contacts',
          middleware: [validateComponent],
          breadcrumb: { name: 'my_contacts' },
          dict: DICTIONARY_MY_CONTACTS,
          menuId: 5,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'person', dynamic: true },
          component: 'user_contacts',
          middleware: [validateComponent],
          dict: DICTIONARY_CONTACT,
          parentMenuId: 5,
        },
        path: ':id',
        name: 'MyContact',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/leads/Contact'),
      },
    ],
  },
];

export const printContacts = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_MY_CONTACTS,
    },
    path: '/sales/my-contacts/print',
    name: 'PrintUserContacts',
    component: () => import(/* webpackChunkName: "print-contacts" */ '@/views/print/PrintContacts'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_CONTACT,
    },
    path: '/sales/my-contacts/:id/print',
    name: 'PrintUserContact',
    component: () => import(/* webpackChunkName: "print-contact" */ '@/views/print/print-pages/PagePrintContact'),
  },
];
