import { activities } from './activities';
import { companies, printCompanies } from './companies';
import { contacts, printContacts } from './contacts';
import { files } from './files';
import { printProjects, projects } from './projects';
import { printTenders, tenders } from './tenders';
import { dashboardContainer, printContainer } from '../../containers';
import { notes } from './notes';

export default [
  printContainer({
    path: '/sales/print',
    redirect: '/sales/my-projects/print',
    name: 'SalesPrint',
    meta: {
      breadcrumb: { name: 'sales' },
    },
    children: [...printCompanies, ...printProjects, ...printTenders, ...printContacts],
  }),
  dashboardContainer({
    path: '/sales',
    redirect: '/sales/my-projects',
    name: 'Sales',
    meta: {
      breadcrumb: { name: 'sales' },
      menuId: 1,
    },
    children: [...activities, ...companies, ...projects, ...tenders, ...contacts, ...files, ...notes],
  }),
];
