import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SYSTEM_CUSTOM_FIELD_METRIC_EDITOR = {
  name: 'SystemCustomFieldMetricEditor',
  vocabularies: ['custom_field_metrics'],
};

export default [
  {
    path: 'custom-field-metrics',
    redirect: '/system/custom-field-metrics',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Custom field metrics' },
          component: 'custom_field_metrics_list',
          middleware: [validateComponent],
          menuId: 76,
          dict: {
            name: 'SystemCustomFieldMetrics',
            vocabularies: ['custom_field_metrics'],
          },
        },
        path: '/system/custom-field-metrics',
        name: 'SystemCustomFieldMetrics',
        component: () =>
          import(
            /* webpackChunkName: "custom-field-metrics" */ '@/views/system/custom-field-metrics/CustomFieldMetricsList'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit custom field metric', dynamic: true },
          component: 'custom_field_metrics_list',
          middleware: [validateComponent],
          parentMenuId: 76,
          dict: DICTIONARY_SYSTEM_CUSTOM_FIELD_METRIC_EDITOR,
        },
        path: ':id/edit',
        name: 'SystemCustomFieldMetricEditor',
        component: () =>
          import(
            /* webpackChunkName: "custom-field-metric-editor" */ '@/views/system/custom-field-metrics/CustomFieldMetricEditor'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Create custom field metric' },
          component: 'custom_field_metrics_list',
          middleware: [validateComponent],
          parentMenuId: 76,
          dict: DICTIONARY_SYSTEM_CUSTOM_FIELD_METRIC_EDITOR,
        },
        path: 'new',
        name: 'SystemCustomFieldMetricNew',
        component: () =>
          import(
            /* webpackChunkName: "custom-field-metric-editor" */ '@/views/system/custom-field-metrics/CustomFieldMetricEditor'
          ),
      },
    ],
  },
];
