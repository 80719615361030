import MiscRepository from '@/api/repositories/miscRepository';
import IndexedDBStorage from '@/shared/storage/drivers/indexedDBStorage';
import { LANGUAGES, DICT_SECTION_TYPES, PROJECT_STAGES } from '@/config/enums';
import Vue from 'vue';
import _ from 'lodash';
import vars from '@/config/vars';
import VuexI18n from 'vuex-i18n';
import router from '@/router';
import { convertArrayToObject, debugLog, filterItems } from '@/shared/utils';
import lookupEnumsConfig from '@/config/lookupEnums';
import { wait } from '@/shared/utils';

const idbDict = IndexedDBStorage('DictionaryStorage');
const idbLookups = IndexedDBStorage('LookupsStorage');

//
// Section:
//   - type
//   - key
//   - version
//   - data

class Dictionary {
  constructor({ Vue, store }) {
    this.vm = Vue;
    this.versions = {};
    this.store = store;

    //
    this.lookupDefaultValues = {
      items: [],
      mapItems: {},
    };
    this.dictSections = [];
    this.routeDicts = [];
    this.locales = Object.entries(LANGUAGES.properties).reduce((acum, [langId, lang]) => {
      const intlVars = lang.intlIsoVars || [];
      const supportedIntlLocales = window.Intl.NumberFormat.supportedLocalesOf(intlVars);

      return {
        ...acum,
        [lang.iso]: {
          id: Number(langId),
          ...lang,
          intlIso: supportedIntlLocales[0] || 'en',
        },
      };
    }, {});

    const lang = this.getCurrentLanguage();
    this.setCurrentLanguage(lang.id);
  }

  clearDictSections() {
    this.dictSections = [];
    this.routeDicts = [];
  }

  async initVersions() {
    try {
      const resVersions = await idbDict.get('dict_versions');

      if (resVersions !== null && Object.keys(resVersions).length > 0) {
        this.versions = _.cloneDeep(resVersions);
      }
    } catch (error) {
      console.log(error);
    }

    return new Promise((resolve) => resolve());
  }

  async initDictSections(dict) {
    const initPromises = [];
    let res = [];

    if (typeof dict === 'object' && Object.keys(dict).length > 0) {
      // get actual versions from index db
      await this.initVersions();

      // handle init sections
      const newSections = this.getDictSections(dict);
      const sectionsForInit = this.getSectionsForInit(newSections);

      if (sectionsForInit.length > 0) {
        debugLog(['[Dictionary plugin] Start init sections', newSections, sectionsForInit]);

        this.dictSections = this.getUpdatedDictSections(sectionsForInit);

        sectionsForInit.forEach((section) => {
          initPromises.push(this.initDictSection(section));
        });
      }
    }

    res = await Promise.all(initPromises);

    if (this.dictSections.every((el) => el.inited)) {
      const sectionsForPatch = this.dictSections.filter((el) => el.data !== null && !el.inStorage);

      if (sectionsForPatch.length > 0) {
        await this.patchSectionsToIndexDb(sectionsForPatch);
      }
    }

    return new Promise((resolve) => resolve(res));
  }

  async getSectionFromStorage(section) {
    const lang = this.getCurrentLanguage('iso');
    let storageSection = null;
    let idbSection = null;

    try {
      switch (section.type) {
        case DICT_SECTION_TYPES.VOCABULARY:
          idbSection = await idbDict.get(`${section.type}.${lang}`);
          storageSection = idbSection ? _.get(idbSection, section.key) : null;
          break;
        case DICT_SECTION_TYPES.LOOKUPS:
          storageSection = await idbLookups.get(`${section.key}.${lang}`);
          break;
        default:
      }
    } catch (error) {
      console.log(error);
    }

    return new Promise((resolve) => resolve(storageSection));
  }

  async loadSectionFromServer(section) {
    const query = {
      type: section.type,
      section: section.key,
      language_id: this.getCurrentLanguage('id'),
    };
    let loadedSection;

    try {
      const response = await MiscRepository.dict(query);

      debugLog([`[Dictionary plugin] **** loaded ${section.type}`, response]);

      if (response && _.has(response, 'section') && _.has(response, 'dict.items')) {
        const { dict } = response;

        this.storedDictSection(section, dict);
        loadedSection = _.cloneDeep(dict);

        if (section.version !== dict.version) {
          debugLog([
            `[Dictionary plugin] '${section.key}' version incorrect.`,
            section.version,
            dict.version,
            section.version === dict.version,
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return new Promise((resolve) => resolve(loadedSection));
  }

  async initDictSection(section) {
    const storageSection = await this.getSectionFromStorage(section);
    let resSection = null;

    // if section exist in storage
    if (storageSection && storageSection.version === section.version) {
      resSection = _.cloneDeep(storageSection);
      this.storedDictSection(section, storageSection, true);

      debugLog([`[Dictionary plugin] ${section.type} exist`, section, storageSection]);
    } else {
      debugLog([`[Dictionary plugin] #####${section.type} not exist`, section, storageSection]);
    }

    // if section does not exist in storage
    if (!resSection) {
      resSection = await this.loadSectionFromServer(section);
    }

    if (!resSection && storageSection) {
      this.storedDictSection(section, storageSection, true);
      resSection = _.cloneDeep(storageSection);
    }

    this.finishInitSection(section);

    return new Promise((resolve) => resolve(resSection));
  }

  getDictSections(dict) {
    let sections = [];

    Object.keys(dict).forEach((sectionType) => {
      const dictSections = dict[sectionType] || [];

      const newSections = dictSections.map((sectionKey) => {
        const sectionVersion = _.get(this.versions, `${sectionType}.${sectionKey}`);
        return {
          id: `${sectionType}.${sectionKey}.${sectionVersion}`,
          type_key: `${sectionType}.${sectionKey}`,
          type: sectionType,
          key: sectionKey,
          version: sectionVersion,
          data: null,
          inStorage: false,
          inited: false,
        };
      });

      sections = _.concat(sections, newSections);
    });

    return sections;
  }

  getSectionsForInit(newSections) {
    return newSections.filter((newSection) => {
      return !this.dictSections.some((dictSect) => dictSect.id === newSection.id);
    });
  }

  getUpdatedDictSections(sectionsForInit) {
    const dictSections = this.dictSections.filter((section) => {
      return !sectionsForInit.some((el) => el.type_key === section.type_key);
    });
    return _.unionBy(sectionsForInit, dictSections, 'id');
  }

  finishInitSection(section) {
    const sectionIndex = _.findIndex(this.dictSections, { id: section.id });

    if (sectionIndex > -1 && this.dictSections[sectionIndex]) {
      this.dictSections[sectionIndex].inited = true;
    }
  }

  async patchSectionsToIndexDb(sections) {
    const lang = this.getCurrentLanguage('iso');
    const sectionTypes = Object.values(DICT_SECTION_TYPES);

    debugLog(['[Dictionary plugin] patchSectionsToIndexDb', sections]);

    for (const sectionType of sectionTypes) {
      const patchSections = sections.filter((el) => el.type === sectionType);

      if (patchSections.length > 0) {
        const patchData = {};

        // vocabularies
        if (sectionType === DICT_SECTION_TYPES.VOCABULARY) {
          patchSections.forEach((section) => {
            Object.assign(patchData, {
              [section.key]: section.data,
            });
          });
          debugLog(['[Dictionary plugin] vocabularies patch', patchData]);
          await idbDict.patch(`${sectionType}.${lang}`, patchData);

          // lookups
        } else if (sectionType === DICT_SECTION_TYPES.LOOKUPS) {
          for (const section of patchSections) {
            debugLog(['[Dictionary plugin] lookups patch', section.data]);
            await idbLookups.patch(`${section.key}.${lang}`, section.data);
          }
        }
      }
    }

    //
    const sectionsId = sections.map((el) => el.id);
    this.dictSections = this.dictSections.map((sect) => {
      return {
        ...sect,
        inStorage: sectionsId.includes(sect.id),
      };
    });

    return new Promise((resolve) => resolve());
  }

  storedDictSection(section, dictData, fromStorage = false) {
    const lang = this.getCurrentLanguage('iso');

    switch (section.type) {
      case DICT_SECTION_TYPES.VOCABULARY:
        Vue.i18n.add(lang, {
          [section.type]: {
            [section.key]: dictData.items,
          },
        });
        break;

      case DICT_SECTION_TYPES.LOOKUPS:
        const preparedLookupData = this.prepareLookupsForStore({
          section: section.key,
          dict: dictData,
        });
        this.store.commit('SET_LOOKUPS', preparedLookupData);
        break;
      default:
    }

    if (!fromStorage) {
      const sectionIndex = _.findIndex(this.dictSections, { id: section.id });

      if (sectionIndex > -1 && this.dictSections[sectionIndex]) {
        this.dictSections[sectionIndex].data = _.cloneDeep(dictData);
      }
    }
  }

  prepareLookupsForStore(payload) {
    const sectionKey = _.get(payload, 'section');
    const dictItems = _.get(payload, 'dict.items');
    const lookupEnums = {};

    if (!sectionKey || !dictItems) {
      return;
    }

    const lookupData = dictItems.reduce((obj, lookupItem) => {
      const lookupItems = filterItems(lookupItem.items, {
        parentRequired: true,
        filterFunc: (item) => {
          return !_.has(item, 'countries') || (item.countries || []).length > 0;
        },
      }).map((item) => {
        let itemId = item.id;

        if (_.has(item, 'key')) {
          const parsed = parseInt(item.key, 10);

          if (!isNaN(parsed) || item.key === null) {
            itemId = item.key === null ? null : parsed;
          }
        }

        return {
          ...item,
          id: itemId,
        };
      });

      const flattenItems = lookupItems.reduce(
        (acum, item) => [
          ...acum,
          { ...item, items: [] },
          ...(item.items || []).map((el) => ({ ...el, parent_id: item.id })),
        ],
        [],
      );

      if (lookupEnumsConfig.hasOwnProperty(lookupItem.lookup)) {
        const lookupEnumConfig = lookupEnumsConfig[lookupItem.lookup];

        lookupEnums[lookupEnumConfig.enumKey] = lookupEnumConfig.enumKeys.reduce(
          (acum, keyData) => {
            const lookupItemByRsmCode = lookupItems.find((el) => el.rsm_code === keyData.rsmCode);
            return lookupItemByRsmCode
              ? {
                  ...acum,
                  [keyData.key]: lookupItemByRsmCode.id,
                }
              : acum;
          },
          {},
        );
      }

      return {
        ...obj,
        [lookupItem.lookup]: {
          ...lookupItem,
          items: lookupItems,
          mapItems: convertArrayToObject(flattenItems, 'id'),
        },
      };
    }, {});

    return {
      sectionKey,
      lookupEnums,
      lookupData,
    };
  }

  setCurrentLanguage(langId) {
    const locale = _.get(LANGUAGES.properties, `${langId}.iso`);

    if (!locale) {
      return;
    }

    window.localStorage.setItem('LANG_ID', langId);
    document.documentElement.setAttribute('lang', locale);

    Vue.i18n.fallback(locale);
    Vue.i18n.set(locale);
  }

  getCurrentLanguageId() {
    let langId = parseInt(window.localStorage.getItem('LANG_ID'));

    if (!langId) {
      const highLevelDomain = window.location.hostname.split('.').slice(-1).join('.');
      const localeLangs = Object.values(this.locales);
      const lang = _.find(localeLangs, { domain: highLevelDomain });

      langId = lang?.id;
    }

    return langId || vars.DEFAULT_LANG_ID;
  }

  getCurrentLanguage(param = '') {
    let locale = Vue?.i18n?.locale();

    if (!locale) {
      const langId = this.getCurrentLanguageId();
      locale = _.get(LANGUAGES.properties, `${langId}.iso`);
    }

    if (!locale) {
      return;
    }

    return _.get(this.locales, param ? `${locale}.${param}` : locale);
  }

  getDictSectionsFromRoute(route) {
    const dict = route.matched.reduce(
      (acum, route) => {
        const routeDict = route?.meta?.dict;
        if (!routeDict) return acum;

        acum.vocabularies = _.union(acum.vocabularies, routeDict.vocabularies || []);
        acum.lookups = _.union(acum.lookups, routeDict.lookups || []);
        return acum;
      },
      { vocabularies: [], lookups: [] },
    );

    // temp solution
    dict.lookups = _.union(dict.lookups || [], ['global']);
    dict.vocabularies = _.union(dict.vocabularies || [], ['global', 'general']);

    return dict;
  }

  async changeDictLanguage(payload = {}) {
    const { langId, route, silent } = payload;
    const currentRoute = router.currentRoute;
    const hasToken = currentRoute.query.hasOwnProperty('token');
    const isSilent = silent || hasToken;

    this.store.commit('SET_LOADING_DICT_ROUTE', isSilent ? 'silent' : 'main');
    this.setCurrentLanguage(langId);

    const langIdUrl = currentRoute.query.set_language_id;
    if (langIdUrl && langId !== Number(langIdUrl)) {
      await router.replace({
        query: {
          ...currentRoute.query,
          set_language_id: langId,
        },
      });
    }

    if (!isSilent) {
      window.location.reload();
      return;
    }

    this.clearDictSections();

    await this.initDictSectionsByRoute({
      route: route || currentRoute,
      silent: isSilent,
    });
    await wait(500);
    this.store.commit('SET_LOADING_DICT_ROUTE', '');
  }

  async initDictSectionsByRoute(payload = {}) {
    const { route, silent } = payload;
    const routeDicts = route.matched.reduce((acum, matchedRoute) => {
      return [...acum, matchedRoute?.meta?.dict];
    }, []);
    let loadingRouteDict = '';

    if (!silent) {
      if (this.routeDicts[0]?.name !== routeDicts[0]?.name) {
        loadingRouteDict = 'main';
      } else if (JSON.stringify(this.routeDicts) !== JSON.stringify(routeDicts)) {
        loadingRouteDict = 'secondary';
      } else {
        return;
      }

      this.store.commit('SET_LOADING_DICT_ROUTE', loadingRouteDict);
    }

    const dict = this.getDictSectionsFromRoute(route);
    const res = await this.initDictSections(dict);

    this.routeDicts = _.clone(routeDicts);

    if (!silent) {
      this.store.commit('SET_LOADING_DICT_ROUTE', '');
    }

    return Promise.resolve(res);
  }

  checkLookupAccess(lookup = {}, countries = [], listTypeId = null) {
    let isAccessAllowed = true;

    if (_.has(lookup, 'access') && listTypeId) {
      isAccessAllowed = lookup.access.some((el) => {
        return (
          el.list_type_id === listTypeId &&
          el.countries.some((countryId) => countries.includes(countryId))
        );
      });
    }

    return isAccessAllowed;
  }

  getLookupItems(lookup = {}, payload = {}) {
    const { attr, countries = [] } = payload;
    let res = _.get(lookup, attr) || this.lookupDefaultValues[attr] || [];

    if (attr !== 'items') {
      return res;
    }

    res = filterItems(res, {
      parentRequired: true,
      filterFunc: (lookup, prevCountries) => {
        if (!_.has(lookup, 'countries')) {
          return true;
        }

        const lookupCountries = lookup.countries || [];
        const allowCountries = prevCountries || countries;

        if (!allowCountries?.length) {
          return lookupCountries.length > 0;
        }

        return lookupCountries.filter((countryId) => allowCountries.includes(countryId));
      },
    });

    return res;
  }

  lDict(section = '', options = {}) {
    const params = _.defaults({}, options, {
      attr: 'items',
      all: false,
      listTypeId: null,
      countries: null,
    });
    const lookup = this.store.getters.getLookups(section);
    const defaultValue = this.lookupDefaultValues[params.attr] || [];

    if (!lookup) {
      return defaultValue;
    }

    let countries =
      params.countries || this.store.getters['Account/getSelectionsValue']('countries');
    let isAccessAllowed = true;
    let res = defaultValue;

    if (params.attr === 'items' && params.all) {
      countries = params.countries;
    } else if (params.attr === 'items') {
      isAccessAllowed = !countries || this.checkLookupAccess(lookup, countries, params.listTypeId);
    }

    if (isAccessAllowed) {
      res = this.getLookupItems(lookup, { ...params, countries });
    }

    return res;
  }

  lFind(section = '', params = {}) {
    const lookup = this.store.getters.getLookups(section);
    const lookupMapItems = _.get(lookup, 'mapItems') || {};
    const { id, ids, prop } = params;
    let res = null;

    if (ids && Array.isArray(ids)) {
      const lookups = ids.reduce(
        (arr, itemId) => (lookupMapItems[itemId] ? [...arr, lookupMapItems[itemId]] : arr),
        [],
      );
      res = prop ? lookups.map((el) => el[prop]) : lookups;
    } else if (id) {
      const propName = prop ? `${id}.${prop}` : id;
      res = _.get(lookupMapItems, propName) || null;
    }

    return res;
  }

  lFilter(section = '', config = {}) {
    const defaultConfig = {
      ids: null,
      constFilterKey: null,
      constFilters: null,
      listTypeId: null,
      countries: null,
    };
    const cfg = _.defaults(config, defaultConfig);
    const constFilterIds = cfg.constFilterKey
      ? _.get(cfg.constFilters, cfg.constFilterKey) ||
        this.store.getters['Account/getSelectionsValue'](cfg.constFilterKey)
      : null;
    const filterIds = cfg.ids || constFilterIds || [];
    const countries = cfg.countries || _.get(cfg.constFilters, 'countries');

    Object.assign(cfg, { countries });

    //
    let lookupItems = this.lDict(section, cfg);

    if (filterIds && filterIds.length > 0) {
      lookupItems = filterItems(lookupItems, { ids: filterIds });
    }

    return lookupItems;
  }

  vDict(name = null, params = null) {
    let res = '';

    try {
      res = Vue.i18n.translate(`vocabularies.${name}`, '', params);
    } catch (e) {
      console.log('### vDict error:', `vocabularies.${name}`, params);
      console.log('### Error details.', e);
    }

    return res;
  }

  vDictExists(key = null) {
    return Vue.i18n.keyExists(`vocabularies.${key}`);
  }
}

export default {
  install(Vue, { store }) {
    Vue.use(VuexI18n.plugin, store);

    const dict = new Dictionary({ Vue, store });

    //
    Object.assign(Vue.prototype, {
      $changeDictLanguage: (payload) => dict.changeDictLanguage(payload),
      $getDictLanguage: (param) => dict.getCurrentLanguage(param),
      $initDictSectionsByRoute: (payload) => dict.initDictSectionsByRoute(payload),
      $lDict: (section, options) => dict.lDict(section, options),
      $lFind: (section, params, listTypeId) => dict.lFind(section, params, listTypeId),
      $lFilter: (section, config) => dict.lFilter(section, config),
      $vDict: (name, params) => dict.vDict(name, params),
      $vDictExists: (name) => dict.vDictExists(name),
    });

    // Lookups global methods
    Vue.prototype.$lGetRolesByPriority = (config = {}) => {
      const rolesLookupKey = config.rolesLookupKey || 'client.company_roles';
      const { roleIds = [] } = config;
      let roles = dict.lDict(rolesLookupKey, config);

      if (roleIds.length > 0) {
        roles = filterItems(roles, { ids: roleIds });
      }

      roles.forEach((role) => {
        role.items.sort((a, b) => a.name.localeCompare(b.name));
      });

      roles
        .sort((a, b) => a.priority - b.priority)
        .sort((a, b) => a.project_stage_id - b.project_stage_id);

      return roles;
    };

    Vue.prototype.$lGetItemCountries = (item) => {
      let res = [];

      if (item?.countries) {
        const userCountries = store.getters['Account/getSelectionsValue']('countries') || [];
        const countries = Vue.prototype.$lDict('global.countries');
        const allowCountries = item.countries.filter((countryId) =>
          userCountries.includes(countryId),
        );

        if (allowCountries.length !== userCountries.length) {
          res = countries.filter((el) => allowCountries.includes(el.id)).map((el) => el.code);
        }
      }

      return res;
    };

    Vue.prototype.$lGetCompanyStageRoles = (config = {}) => {
      const cfg = _.defaults(config, {
        listTypeId: null,
        isConstruction: false,
        roleIds: [],
        rolesLookupKey: 'client.company_roles',
      });
      const roles = Vue.prototype.$lGetRolesByPriority(cfg);
      let stages = Vue.prototype.$lDict('client.project_stages', cfg);

      if (!cfg.isConstruction) {
        stages = stages.filter((stage) => stage.id !== PROJECT_STAGES.CONSTRUCTION);
      }

      return stages
        .map((stage) => {
          return {
            ...stage,
            id: stage.id,
            items: roles.filter((el) => el.project_stage_id === stage.id),
          };
        })
        .filter(
          (stage) =>
            (stage.items && stage.items.length > 0) || stage.id === PROJECT_STAGES.CONSTRUCTION,
        );
    };
  },
};
