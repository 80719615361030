import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_ALL_COMPANIES = {
  name: 'AllCompanies',
  vocabularies: ['companies', 'entity_list', 'triggers', 'search', 'companies', 'notes', 'activities'],
};

const DICTIONARY_COMPANY = {
  name: 'Company',
  vocabularies: [
    'entity_list',
    'company',
    'triggers',
    'project',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'fields_company_related',
    'notes',
    'activities',
    'search',
    'company_analysis',
  ],
};

export const companies = [
  {
    path: 'companies',
    redirect: '/leads/companies',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/leads/companies',
        name: 'Companies',
        component: () => import(/* webpackChunkName: "all-companies" */ '@/views/leads/AllCompanies'),
        meta: {
          component: 'all_companies',
          middleware: [validateComponent],
          breadcrumb: { name: 'all_companies' },
          dict: DICTIONARY_ALL_COMPANIES,
          menuId: 10,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'company', dynamic: true },
          component: 'company',
          middleware: [validateComponent],
          dict: DICTIONARY_COMPANY,
          parentMenuId: 10,
        },
        path: ':id',
        name: 'Company',
        component: () => import(/* webpackChunkName: "company" */ '@/views/leads/Company'),
      },
    ],
  },
];

export const printCompanies = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_ALL_COMPANIES,
    },
    path: '/leads/companies/print',
    name: 'PrintCompanies',
    component: () => import(/* webpackChunkName: "print-companies" */ '@/views/print/PrintCompanies'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_COMPANY,
    },
    path: '/leads/companies/:id/print',
    name: 'PrintCompany',
    component: () => import(/* webpackChunkName: "print-company" */ '@/views/print/print-pages/PagePrintCompany'),
  },
];
