import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_USERS = {
  name: 'Users',
  vocabularies: ['users', 'entity_list', 'search', 'subscriptions', 'system_modules'],
};

export default [
  {
    path: 'users',
    redirect: '/support/users',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'all_users',
          middleware: [validateComponent],
          breadcrumb: {
            name: 'Users',
          },
          dict: DICTIONARY_USERS,
          menuId: 42,
        },
        path: '/support/users',
        name: 'SupportUsers',
        component: () => import(/* webpackChunkName: "support-users" */ '@/views/support/users/Users'),
      },
      {
        meta: {
          breadcrumb: { name: 'Create user' },
          component: 'all_users',
          middleware: [validateComponent],
          dict: DICTIONARY_USERS,
          parentMenuId: 42,
        },
        path: 'new',
        name: 'SupportEditorUserNew',
        component: () => import(/* webpackChunkName: "support-user-editor" */ '@/views/support/users/EditorUser'),
      },
      {
        meta: {
          breadcrumb: { name: 'User', dynamic: true },
          component: 'all_users',
          middleware: [validateComponent],
          dict: {
            name: 'SupportUser',
            vocabularies: [
              'user_profile',
              'triggers',
              'users',
              'myran_logs_user_profiles',
              'email_sender_logs',
              'email_sender_events',
              'fixed_addons',
              'user_transfer_tasks',
              'search',
              'subscriptions',
              'entity_list',
              'smart_selections',
              'system_modules',
            ],
            lookups: ['user_profile'],
          },
          parentMenuId: 42,
        },
        path: ':id',
        name: 'SupportUser',
        component: () => import(/* webpackChunkName: "support-user" */ '@/views/support/users/User'),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit user', dynamic: true },
          component: 'all_users',
          middleware: [validateComponent],
          dict: DICTIONARY_USERS,
          parentMenuId: 42,
        },
        path: ':id/edit',
        name: 'SupportEditorUser',
        component: () => import(/* webpackChunkName: "support-user-editor" */ '@/views/support/users/EditorUser'),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit user organization', dynamic: true },
          component: 'all_users',
          middleware: [validateComponent],
          dict: {
            name: 'SupportClientUserEditor',
            lookups: ['user_profile'],
            vocabularies: ['users', 'entity_list', 'search', 'system_modules', 'clients'],
          },
          parentMenuId: 42,
        },
        path: ':id/edit-client/:clientId',
        name: 'SupportClientUserEditor',
        component: () =>
          import(/* webpackChunkName: "support-client-user-editor" */ '@/views/support/users/ClientUserEditor.vue'),
      },
    ],
  },
];
