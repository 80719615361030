import validateComponent from '@/router/middleware/validateComponent';

export const activities = [
  {
    meta: {
      breadcrumb: { name: 'my_activities' },
      component: 'user_activities',
      middleware: [validateComponent],
      dict: {
        name: 'MyActivities',
        vocabularies: [
          'projects',
          'project',
          'tenders',
          'companies',
          'contacts',
          'sales_activities',
          'activities',
          'activities',
          'search',
          'entity_list',
        ],
      },
      menuId: 6,
    },
    path: 'my-activities',
    name: 'MyActivities',
    component: () => import(/* webpackChunkName: "my-activities" */ '@/views/sales/MyActivities'),
  },
];
