import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

const DICTIONARY_SUPPORT_WEBHOOK = {
  name: 'SupportWebhook',
  vocabularies: ['webhooks'],
};

const DICTIONARY_SETTINGS_WEBHOOK = {
  name: 'SettingsWebhook',
  vocabularies: ['webhooks'],
};

const DICTIONARY_API_CLIENTS = {
  name: 'ApiClients',
  vocabularies: ['api_clients', 'search'],
};

const DICTIONARY_SUPPORT_API_CLIENT = {
  name: 'SupportApiClient',
  vocabularies: ['api_clients', 'fixed_addons', 'system_modules'],
};

export default [
  dashboardContainer({
    meta: {
      breadcrumb: { name: 'api-settings' },
      menuId: 40,
    },
    path: '/api-settings',
    redirect: '/api-settings/api-clients',
    name: 'ApiSettings',
    children: [
      {
        path: 'api-clients',
        redirect: '/api-settings/api-clients',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'api_clients',
              middleware: [validateComponent],
              breadcrumb: { name: 'Api Clients' },
              dict: DICTIONARY_API_CLIENTS,
              menuId: 43,
            },
            path: '/api-settings/api-clients',
            name: 'SupportApiClients',
            component: () =>
              import(/* webpackChunkName: "support-api-clients" */ '@/views/support/api-clients/ListApiClients'),
          },
          {
            meta: {
              breadcrumb: { name: 'Create api client' },
              component: 'api_clients',
              middleware: [validateComponent],
              dict: DICTIONARY_API_CLIENTS,
              parentMenuId: 43,
            },
            path: 'new',
            name: 'SupportEditorApiClientNew',
            component: () =>
              import(/* webpackChunkName: "support-api-client-editor" */ '@/views/support/api-clients/EditorApiClient'),
          },
          {
            meta: {
              breadcrumb: { name: 'Api client', dynamic: true },
              component: 'all_users',
              middleware: [validateComponent],
              dict: DICTIONARY_SUPPORT_API_CLIENT,
              parentMenuId: 43,
            },
            path: ':id',
            name: 'SupportApiClient',
            component: () =>
              import(/* webpackChunkName: "support-api-client" */ '@/views/support/api-clients/ApiClient'),
          },
          {
            meta: {
              breadcrumb: { name: 'Edit api client', dynamic: true },
              component: 'api_clients',
              middleware: [validateComponent],
              dict: DICTIONARY_API_CLIENTS,
              parentMenuId: 43,
            },
            path: ':id/edit',
            name: 'SupportEditorApiClient',
            component: () =>
              import(/* webpackChunkName: "support-api-client-editor" */ '@/views/support/api-clients/EditorApiClient'),
          },
        ],
      },
      {
        path: 'webhooks',
        redirect: '/api-settings/webhooks',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'webhook_support',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Webhooks',
              },
              dict: DICTIONARY_SUPPORT_WEBHOOK,
              menuId: 57,
            },
            path: '/api-settings/webhooks',
            name: 'SupportWebhooks',
            component: () => import(/* webpackChunkName: "support-webhooks" */ '@/views/support/webhooks/Webhooks'),
          },
          {
            meta: {
              breadcrumb: { name: 'Create webhook' },
              component: 'webhook_support',
              middleware: [validateComponent],
              dict: DICTIONARY_SUPPORT_WEBHOOK,
              parentMenuId: 57,
            },
            path: 'new',
            name: 'SupportEditorWebhookNew',
            component: () =>
              import(/* webpackChunkName: "support-webhook-editor" */ '@/views/support/webhooks/EditorWebhook'),
          },
          {
            meta: {
              breadcrumb: { name: 'Webhook', dynamic: true },
              component: 'webhook_support',
              middleware: [validateComponent],
              dict: DICTIONARY_SUPPORT_WEBHOOK,
              parentMenuId: 57,
            },
            path: ':id',
            name: 'SupportWebhook',
            component: () => import(/* webpackChunkName: "support-webhook" */ '@/views/support/webhooks/Webhook'),
          },
          {
            meta: {
              breadcrumb: { name: 'Edit webhook', dynamic: true },
              component: 'webhook_support',
              middleware: [validateComponent],
              dict: DICTIONARY_SUPPORT_WEBHOOK,
              parentMenuId: 57,
            },
            path: ':id/edit',
            name: 'SupportEditorWebhook',
            component: () =>
              import(/* webpackChunkName: "support-webhook-editor" */ '@/views/support/webhooks/EditorWebhook'),
          },
        ],
      },
      {
        path: 'my-webhooks',
        redirect: '/api-settings/my-webhooks',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'webhook_list',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'My webhooks',
              },
              dict: DICTIONARY_SETTINGS_WEBHOOK,
              menuId: 55,
            },
            path: '/api-settings/my-webhooks',
            name: 'SettingsWebhooks',
            component: () => import(/* webpackChunkName: "settings-webhooks" */ '@/views/settings/webhooks/Webhooks'),
          },
          {
            meta: {
              breadcrumb: { name: 'Create webhook' },
              component: 'webhook_editor',
              middleware: [validateComponent],
              dict: DICTIONARY_SETTINGS_WEBHOOK,
              parentMenuId: 55,
            },
            path: 'new',
            name: 'SettingsEditorWebhookNew',
            component: () =>
              import(/* webpackChunkName: "settings-webhook-editor" */ '@/views/settings/webhooks/EditorWebhook'),
          },
          {
            meta: {
              breadcrumb: { name: 'Webhook', dynamic: true },
              component: 'webhook_view',
              middleware: [validateComponent],
              dict: DICTIONARY_SETTINGS_WEBHOOK,
              parentMenuId: 55,
            },
            path: ':id',
            name: 'SettingsWebhook',
            component: () => import(/* webpackChunkName: "settings-webhook" */ '@/views/settings/webhooks/Webhook'),
          },
          {
            meta: {
              breadcrumb: { name: 'Edit webhook', dynamic: true },
              component: 'webhook_editor',
              middleware: [validateComponent],
              dict: DICTIONARY_SETTINGS_WEBHOOK,
              parentMenuId: 55,
            },
            path: ':id/edit',
            name: 'SettingsEditorWebhook',
            component: () =>
              import(/* webpackChunkName: "settings-webhook-editor" */ '@/views/settings/webhooks/EditorWebhook'),
          },
        ],
      },
      {
        meta: {
          component: 'webhook_queue_list',
          middleware: [validateComponent],
          breadcrumb: { name: 'Webhook queue' },
          dict: {
            name: 'SupportWebhookQueue',
            vocabularies: ['webhooks', 'webhook_queue'],
          },
          menuId: 58,
        },
        path: '/api-settings/webhook-queue',
        name: 'SupportWebhookQueue',
        component: () =>
          import(/* webpackChunkName: "support-webhook-queue" */ '@/views/support/webhooks/WebhookQueue'),
      },
      {
        meta: {
          component: 'user_webhook_queue_list',
          middleware: [validateComponent],
          breadcrumb: { name: 'My webhook queue' },
          dict: {
            name: 'UserWebhookQueue',
            vocabularies: ['webhooks', 'webhook_queue'],
          },
          menuId: 72,
        },
        path: '/api-settings/my-webhook-queue',
        name: 'UserWebhookQueue',
        component: () =>
          import(/* webpackChunkName: "user-webhook-queue" */ '@/views/settings/webhooks/UserWebhookQueue'),
      },
    ],
  }),
];
