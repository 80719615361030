import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SYSTEM_DEFAULT_FIELDSETS = {
  name: 'SystemDefaultFieldsets',
  vocabularies: ['default_fieldsets', 'search', 'projects', 'tenders', 'companies', 'contacts'],
};

export default [
  {
    path: 'default-fieldsets',
    redirect: '/system/default-fieldsets',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'system_default_fieldsets',
          middleware: [validateComponent],
          breadcrumb: { name: 'Default fieldsets' },
          menuId: 54,
          dict: DICTIONARY_SYSTEM_DEFAULT_FIELDSETS,
        },
        path: '/system/default-fieldsets',
        name: 'SystemDefaultFieldsets',
        component: () =>
          import(
            /* webpackChunkName: "system-default-fieldsets" */ '@/views/system/default-fieldsets/ListDefaultFieldsets'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit default fieldset', dynamic: true },
          component: 'system_default_fieldsets',
          middleware: [validateComponent],
          parentMenuId: 54,
          dict: DICTIONARY_SYSTEM_DEFAULT_FIELDSETS,
        },
        path: ':id/edit',
        name: 'SystemEditorDefaultFieldset',
        component: () =>
          import(
            /* webpackChunkName: "system-default-fieldset-editor" */ '@/views/system/default-fieldsets/EditorDefaultFieldset'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Create default fieldset' },
          component: 'system_default_fieldsets',
          middleware: [validateComponent],
          parentMenuId: 54,
          dict: DICTIONARY_SYSTEM_DEFAULT_FIELDSETS,
        },
        path: 'new',
        name: 'SystemEditorDefaultFieldsetNew',
        component: () =>
          import(
            /* webpackChunkName: "system-default-fieldset-editor" */ '@/views/system/default-fieldsets/EditorDefaultFieldset'
          ),
      },
    ],
  },
];
