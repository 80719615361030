import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

export default [
  dashboardContainer({
    meta: {
      component: 'usage_stats_clients',
      breadcrumb: { name: 'usage_statistics' },
      menuId: 51,
    },
    path: '/usage-statistics',
    redirect: '/usage-statistics/clients',
    name: 'UsageStatistics',
    children: [
      {
        path: 'clients',
        redirect: '/usage-statistics/clients',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/usage-statistics/clients',
            name: 'UsageStatisticsClients',
            component: () =>
              import(/* webpackChunkName: "usage-stats-clients" */ '@/views/usage-statistics/UsageStatsClients'),
            meta: {
              component: 'usage_stats_clients',
              breadcrumb: { name: 'usage_statistics_clients' },
              middleware: [validateComponent],
              dict: {
                name: 'UsageStatisticsClients',
                vocabularies: ['search', 'usage_stats', 'clients'],
              },
              menuId: 52,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'usage_statistics_client', dynamic: true },
              component: 'usage_stats_client',
              middleware: [validateComponent],
              dict: {
                name: 'UsageStatisticsClient',
                vocabularies: ['search', 'usage_stats', 'users'],
              },
              parentMenuId: 52,
            },
            path: ':id',
            name: 'UsageStatisticsClient',
            component: () =>
              import(/* webpackChunkName: "usage-stats-client" */ '@/views/usage-statistics/UsageStatsClient'),
          },
        ],
      },
      {
        path: 'users',
        redirect: '/usage-statistics/users',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/usage-statistics/users',
            name: 'UsageStatisticsUsers',
            component: () =>
              import(/* webpackChunkName: "usage-stats-users" */ '@/views/usage-statistics/UsageStatsUsers'),
            meta: {
              component: 'usage_stats_users',
              breadcrumb: { name: 'usage_statistics_users' },
              middleware: [validateComponent],
              dict: {
                name: 'UsageStatisticsUsers',
                vocabularies: ['search', 'usage_stats', 'users'],
              },
              menuId: 53,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'usage_statistics_user', dynamic: true },
              component: 'usage_stats_user',
              middleware: [validateComponent],
              dict: {
                name: 'UsageStatisticsUser',
                vocabularies: ['usage_stats', 'users'],
              },
              parentMenuId: 53,
            },
            path: ':id',
            name: 'UsageStatisticsUser',
            component: () =>
              import(/* webpackChunkName: "usage-stats-user" */ '@/views/usage-statistics/UsageStatsUser'),
          },
        ],
      },
    ],
  }),
];
