import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SYSTEM_CUSTOM_FIELD_SELECTION_METRIC_EDITOR = {
  name: 'SystemCustomFieldSelectionMetricEditor',
  vocabularies: ['custom_field_metrics'],
};

export default [
  {
    path: 'custom-field-selection-metrics',
    redirect: '/system/custom-field-selection-metrics',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Custom field selection metrics' },
          component: 'custom_field_selection_metrics_list',
          middleware: [validateComponent],
          menuId: 76,
          dict: {
            name: 'SystemCustomFieldSelectionMetrics',
            vocabularies: ['custom_field_metrics'],
          },
        },
        path: '/system/custom-field-selection-metrics',
        name: 'SystemCustomFieldSelectionMetrics',
        component: () =>
          import(
            /* webpackChunkName: "custom-field-selection-metrics" */ '@/views/system/custom-field-selection-metrics/CustomFieldSelectionMetricsList'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit custom field selection metric', dynamic: true },
          component: 'custom_field_selection_metrics_list',
          middleware: [validateComponent],
          parentMenuId: 76,
          dict: DICTIONARY_SYSTEM_CUSTOM_FIELD_SELECTION_METRIC_EDITOR,
        },
        path: ':id/edit',
        name: 'SystemCustomFieldSelectionMetricEditor',
        component: () =>
          import(
            /* webpackChunkName: "custom-field-selection-metric-editor" */ '@/views/system/custom-field-selection-metrics/CustomFieldSelectionMetricEditor'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Create custom field selection metric' },
          component: 'custom_field_selection_metrics_list',
          middleware: [validateComponent],
          parentMenuId: 76,
          dict: DICTIONARY_SYSTEM_CUSTOM_FIELD_SELECTION_METRIC_EDITOR,
        },
        path: 'new',
        name: 'SystemCustomFieldSelectionMetricNew',
        component: () =>
          import(
            /* webpackChunkName: "custom-field-selection-metric-editor" */ '@/views/system/custom-field-selection-metrics/CustomFieldSelectionMetricEditor'
          ),
      },
    ],
  },
];
