import { printProjects, projects } from './projects';
import { printTenders, tenders } from './tenders';
import { contacts, printContacts } from './contacts';
import { companies, printCompanies } from './companies';
import { dashboardContainer, printContainer } from '../../containers';

export default [
  printContainer({
    path: '/leads/print',
    redirect: '/leads/projects/print',
    name: 'LeadsPrint',
    meta: {
      breadcrumb: { name: 'leads' },
    },
    children: [...printCompanies, ...printContacts, ...printProjects, ...printTenders],
  }),
  dashboardContainer({
    path: '/leads',
    redirect: '/leads/projects',
    name: 'Leads',
    meta: {
      breadcrumb: { name: 'leads' },
      menuId: 8,
    },
    children: [...projects, ...tenders, ...contacts, ...companies],
  }),
];
