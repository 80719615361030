import merge from 'lodash/merge';

export function dashboardContainer(route = {}) {
  const containerParams = {
    component: () => import('@/containers/DashboardContainer'),
    meta: {
      dict: {
        name: 'DashboardContainer',
        vocabularies: [
          'global',
          'errors',
          'enums',
          'navbar',
          'general',
          'setup_assistant',
          'triggers',
          'setup_companies',
          'breadcrumb',
          'notif_tmpl_general',
          'forms',
          'entities',
          'search',
        ],
        lookups: ['global', 'client'],
      },
    },
  };

  return merge(containerParams, route);
}

export function printContainer(route = {}) {
  const containerParams = {
    component: () => import('@/containers/PrintContainer'),
    meta: {
      dict: {
        name: 'PrintContainer',
        vocabularies: ['global', 'enums', 'forms', 'notif_tmpl_general'],
        lookups: ['client', 'global'],
      },
    },
  };

  return merge(containerParams, route);
}
