import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

const DICTIONARY_VORTAL_CONNECT_CONTACTS = {
  name: 'VortalConnectContacts',
  vocabularies: ['vortal_connect_contacts'],
};

const DICTIONARY_VORTAL_CONNECT_COMPANIES = {
  name: 'VortalConnectCompanies',
  vocabularies: ['vortal_connect_companies'],
};

const DICTIONARY_VORTAL_CONNECT_NOTIFICATION_EMAILS = {
  name: 'VortalConnectNotificationEmails',
  vocabularies: ['vortal_connect_notif_emails'],
};

const DICTIONARY_VORTAL_CONNECT_LOGS = {
  name: 'VortalConnectLogs',
  vocabularies: ['vortal_connect_logs'],
};

export default [
  dashboardContainer({
    path: '/vortal-connect',
    redirect: '/vortal-connect/contacts',
    name: 'VortalConnect',
    meta: {
      breadcrumb: { name: 'vortal_connect' },
      menuId: 62,
    },
    children: [
      {
        path: 'contacts',
        redirect: '/vortal-connect/contacts',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/contacts',
            name: 'VortalConnectContacts',
            component: () =>
              import(/* webpackChunkName: "vortal-connect-contacts" */ '@/views/vortal-connect/Contacts'),
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: DICTIONARY_VORTAL_CONNECT_CONTACTS,
              menuId: 63,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'Edit contact', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: DICTIONARY_VORTAL_CONNECT_CONTACTS,
              parentMenuId: 63,
            },
            path: ':id/edit',
            name: 'VortalConnectEditorContact',
            component: () =>
              import(/* webpackChunkName: "vortal-connect-contact-editor" */ '@/views/vortal-connect/EditorContact'),
          },
        ],
      },
      {
        path: 'companies',
        redirect: '/vortal-connect/companies',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/companies',
            name: 'VortalConnectCompanies',
            component: () =>
              import(/* webpackChunkName: "vortal-connect-companies" */ '@/views/vortal-connect/Companies'),
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: DICTIONARY_VORTAL_CONNECT_COMPANIES,
              menuId: 64,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'Edit company', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: DICTIONARY_VORTAL_CONNECT_COMPANIES,
              parentMenuId: 64,
            },
            path: ':id/edit',
            name: 'VortalConnectEditorCompany',
            component: () =>
              import(/* webpackChunkName: "vortal-connect-company-editor" */ '@/views/vortal-connect/EditorCompany'),
          },
        ],
      },
      {
        path: 'projects',
        redirect: '/vortal-connect/projects',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/projects',
            name: 'VortalConnectProjects',
            component: () =>
              import(/* webpackChunkName: "vortal-connect-projects" */ '@/views/vortal-connect/Projects'),
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: {
                name: 'VortalConnectProjects',
                vocabularies: ['vortal_connect_projects'],
              },
              menuId: 65,
            },
          },
        ],
      },
      {
        path: 'notification-emails',
        redirect: '/vortal-connect/notification-emails',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/notification-emails',
            name: 'VortalConnectNotificationEmails',
            component: () =>
              import(/* webpackChunkName: "vortal-connect-notif-emails" */ '@/views/vortal-connect/NotificationEmails'),
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: DICTIONARY_VORTAL_CONNECT_NOTIFICATION_EMAILS,
              menuId: 66,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'Edit notification email', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: DICTIONARY_VORTAL_CONNECT_NOTIFICATION_EMAILS,
              parentMenuId: 66,
            },
            path: ':id/edit',
            name: 'VortalConnectEditorNotificationEmail',
            component: () =>
              import(
                /* webpackChunkName: "vortal-connect-notif-email-editor" */ '@/views/vortal-connect/EditorNotificationEmail'
              ),
          },
          {
            meta: {
              breadcrumb: { name: 'New notification email', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: DICTIONARY_VORTAL_CONNECT_NOTIFICATION_EMAILS,
              parentMenuId: 66,
            },
            path: 'new',
            name: 'VortalConnectEditorNotificationEmailNew',
            component: () =>
              import(
                /* webpackChunkName: "vortal-connect-notif-email-editor" */ '@/views/vortal-connect/EditorNotificationEmail'
              ),
          },
        ],
      },
      {
        path: 'logs',
        redirect: '/vortal-connect/logs',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/logs',
            name: 'VortalConnectLogs',
            component: () => import(/* webpackChunkName: "vortal-connect-logs" */ '@/views/vortal-connect/Logs'),
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dict: {
                name: 'VortalConnectLogs',
                vocabularies: ['vortal_connect_logs'],
              },
              menuId: 67,
            },
          },
        ],
      },
    ],
  }),
];
