import validateComponent from '@/router/middleware/validateComponent';

export default [
  {
    path: 'dead-message-subscriptions',
    redirect: '/system/dead-message-subscriptions',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Dead message subscriptions' },
          component: 'dead_message_subscriptions',
          middleware: [validateComponent],
          menuId: 74,
          dict: {
            name: 'SystemDeadMessageSubscriptions',
            vocabularies: ['dead_message_subscriptions', 'search'],
          },
        },
        path: '/system/dead-message-subscriptions',
        name: 'SystemDeadMessageSubscriptions',
        component: () =>
          import(
            /* webpackChunkName: "dead-message-subscriptions" */ '@/views/system/dead-message-subscriptions/DeadMessageSubscriptionsList'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit dead message subscription', dynamic: true },
          component: 'dead_message_subscriptions',
          middleware: [validateComponent],
          parentMenuId: 74,
          dict: {
            name: 'SystemDeadMessageSubscriptionEditor',
            vocabularies: ['dead_message_subscriptions'],
          },
        },
        path: ':id/edit',
        name: 'SystemDeadMessageSubscriptionEditor',
        component: () =>
          import(
            /* webpackChunkName: "dead-message-subscription-editor" */ '@/views/system/dead-message-subscriptions/EditorDeadMessageSubscription'
          ),
      },
    ],
  },
];
