import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_ALL_PROJECTS = {
  name: 'AllProjects',
  vocabularies: [
    'projects',
    'entity_list',
    'triggers',
    'search',
    'projects',
    'project',
    'notes',
    'activities',
    'companies',
  ],
};

const DICTIONARY_PROJECT = {
  name: 'Project',
  vocabularies: [
    'entity_list',
    'projects',
    'project',
    'company',
    'triggers',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'notes',
    'activities',
    'search',
  ],
};

export const projects = [
  {
    path: 'projects',
    redirect: '/leads/projects',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/leads/projects',
        name: 'Projects',
        component: () => import(/* webpackChunkName: "all-projects" */ '@/views/leads/AllProjects'),
        meta: {
          component: 'all_projects',
          middleware: [validateComponent],
          breadcrumb: { name: 'all_projects' },
          dict: DICTIONARY_ALL_PROJECTS,
          menuId: 9,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'project', dynamic: true },
          component: 'project',
          middleware: [validateComponent],
          dict: DICTIONARY_PROJECT,
          parentMenuId: 9,
        },
        path: ':id',
        name: 'Project',
        component: () => import(/* webpackChunkName: "project" */ '@/views/leads/Project'),
      },
    ],
  },
];

export const printProjects = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_ALL_PROJECTS,
    },
    path: '/leads/projects/print',
    name: 'PrintProjects',
    component: () => import(/* webpackChunkName: "print-projects" */ '@/views/print/PrintProjects'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_PROJECT,
    },
    path: '/leads/projects/:id/print',
    name: 'PrintProject',
    component: () => import(/* webpackChunkName: "print-project" */ '@/views/print/print-pages/PagePrintProject'),
  },
];
