import authorize from '@/router/middleware/authorize';
import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

const DICTIONARY_COMPANY_ANALYSIS = {
  name: 'CompanyAnalysis',
  vocabularies: ['global', 'company_analysis', 'fields_company_related', 'company_analysis_widget'],
  lookups: ['client', 'global'],
};

const DICTIONARY_PAGE_404 = {
  name: 'Page404',
  vocabularies: ['errors', 'breadcrumb'],
};

export default [
  dashboardContainer({
    path: '/profile',
    children: [
      {
        meta: {
          breadcrumb: { name: 'profile' },
          component: 'user_profile',
          middleware: [validateComponent],
          dict: {
            name: 'UserProfile',
            vocabularies: [
              'user_profile',
              'client_profile',
              'errors',
              'password',
              'api_clients',
              'clients',
              'smart_selections',
              'system_modules',
            ],
            lookups: ['global', 'user_profile'],
          },
        },
        path: '/profile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "user-profile" */ '@/views/profile/UserProfile'),
      },
      {
        meta: {
          breadcrumb: { name: 'Smart insights' },
          component: 'smart_insights',
          middleware: [validateComponent],
        },
        path: '/smart-insights',
        name: 'SmartInsights',
        component: () => import(/* webpackChunkName: "smart-insights" */ '@/views/pages/SmartInsights.vue'),
      },
      {
        meta: {
          breadcrumb: { name: 'Smart search assistant' },
          // component: 'smart_search_assistant',
          // middleware: [validateComponent],
        },
        path: '/smart-search-assistant',
        name: 'SmartSearchAssistant',
        component: () =>
          import(/* webpackChunkName: "smart-search-assistant" */ '@/views/pages/SmartSearchAssistant.vue'),
      },
    ],
  }),
  // {
  //   meta: {
  //     dict: 'DashboardContainer',
  //   },
  //   path: '/privacy-cookies-policy',
  //   component: DashboardContainer,
  //   children: [
  //     {
  //       meta: {
  //         breadcrumb: { name: 'Policy' },
  //         component: 'privacy_policy',
  //         middleware: [authorize],
  //         dict: 'PrivacyCookiesPolicy',
  //       },
  //       path: '/privacy-cookies-policy',
  //       name: 'Policy',
  //       component: PrivacyCookiesPolicy,
  //     },
  //   ],
  // },
  {
    path: '/reissue-token',
    name: 'ReissueToken',
    component: () => import(/* webpackChunkName: "reissue-token" */ '@/views/pages/ReissueToken'),
  },
  {
    meta: {
      dict: {
        name: 'FileDownload',
        vocabularies: ['file_download', 'errors', 'global'],
      },
    },
    path: '/file-download/:id',
    name: 'FileDownload',
    component: () => import(/* webpackChunkName: "file-download" */ '@/views/pages/FileDownload'),
  },
  {
    meta: {
      dict: {
        name: 'PageError',
        vocabularies: ['errors', 'breadcrumb'],
      },
      errorPage: true,
    },
    path: '/error',
    name: 'PageError',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/PageError'),
  },
  {
    meta: {
      dict: {
        name: 'Page500',
        vocabularies: ['errors', 'breadcrumb'],
      },
      errorPage: true,
    },
    path: '/500',
    name: 'Page500',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/Page500'),
  },
  {
    meta: {
      dict: {
        name: 'PageNetworkError',
        vocabularies: ['errors'],
      },
      errorPage: true,
    },
    path: '/network-error',
    name: 'PageNetworkError',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/PageNetworkError'),
  },
  {
    meta: {
      dict: {
        name: 'Forbidden',
        vocabularies: ['errors', 'breadcrumb'],
      },
      errorPage: true,
    },
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/PageForbidden'),
  },
  {
    meta: {
      errorPage: true,
    },
    path: '/expired',
    name: 'SubscriptionExpired',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/SubscriptionExpired'),
  },
  {
    meta: {
      systemPage: true,
      auth: true,
      forbiddenForUserSubdomain: true,
      disableAutoDestination: true,
    },
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/pages/Logout'),
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/redirect',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '@/views/pages/Redirect'),
  },
  {
    meta: {
      auth: true,
      systemPage: true,
      forbiddenForUserSubdomain: true,
    },
    path: '/magic_link',
    name: 'MagicLink',
    component: () => import(/* webpackChunkName: "magic-link" */ '@/views/pages/MagicLink'),
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/login-user',
    name: 'LoginUser',
    component: () => import(/* webpackChunkName: "login-user" */ '@/views/pages/LoginUser'),
  },
  {
    meta: {
      dict: DICTIONARY_COMPANY_ANALYSIS,
      // middleware: [authorize],
      systemPage: true,
    },
    path: '/ca-widget',
    name: 'CompanyAnalysis',
    component: () => import(/* webpackChunkName: "company-analysis" */ '@/views/pages/CompanyAnalysis'),
  },
  {
    meta: {
      dict: DICTIONARY_COMPANY_ANALYSIS,
      middleware: [authorize],
      systemPage: true,
    },
    path: '/ca-redirect',
    name: 'CompanyAnalysisRedirect',
    component: () =>
      import(/* webpackChunkName: "company-analysis-redirect" */ '@/views/pages/CompanyAnalysisRedirect'),
  },
  {
    meta: {
      dict: DICTIONARY_PAGE_404,
      errorPage: true,
    },
    path: '/404',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/Page404'),
    name: 'Page404',
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/maintenance-mode',
    component: () => import(/* webpackChunkName: "maintenance-mode" */ '@/views/pages/MaintenanceMode.vue'),
    name: 'MaintenanceMode',
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/corp-sso',
    component: () => import(/* webpackChunkName: "corporate-auth" */ '@/views/pages/CorporateAuth.vue'),
    name: 'CorporateAuth',
  },
  {
    meta: {
      dict: {
        name: 'ClientAccessDenied',
        vocabularies: ['errors', 'breadcrumb'],
      },
      errorPage: true,
    },
    path: '/client-access-denied',
    name: 'ClientAccessDenied',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/ClientAccessDenied'),
  },
  {
    meta: {
      dict: DICTIONARY_PAGE_404,
    },
    path: '*',
    component: () => import(/* webpackChunkName: "page-error" */ '@/views/pages/Page404'),
  },
];
