import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_MY_TENDERS = {
  name: 'MyTenders',
  vocabularies: ['entity_list', 'tenders', 'global', 'triggers', 'search', 'tenders', 'notes', 'activities', 'project'],
};

const DICTIONARY_TENDER = {
  name: 'Tender',
  vocabularies: [
    'entity_list',
    'tender',
    'project',
    'triggers',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'notes',
    'activities',
    'company',
    'contacts',
    'global',
  ],
};

export const tenders = [
  {
    path: 'my-tenders',
    redirect: '/sales/my-tenders',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/sales/my-tenders',
        name: 'MyTenders',
        component: () => import(/* webpackChunkName: "my-tenders" */ '@/views/sales/MyTenders'),
        meta: {
          requiredQuery: {
            status: 1,
          },
          component: 'user_tenders',
          middleware: [validateComponent],
          breadcrumb: { name: 'my_tenders' },
          dict: DICTIONARY_MY_TENDERS,
          menuId: 3,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'tender', dynamic: true },
          component: 'user_tenders',
          middleware: [validateComponent],
          dict: DICTIONARY_TENDER,
          parentMenuId: 3,
        },
        path: ':id',
        name: 'MyTender',
        component: () => import(/* webpackChunkName: "tender" */ '@/views/leads/Tender'),
      },
    ],
  },
];

export const printTenders = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_MY_TENDERS,
    },
    path: '/sales/my-tenders/print',
    name: 'PrintUserTenders',
    component: () => import(/* webpackChunkName: "print-tenders" */ '@/views/print/PrintTenders'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_TENDER,
    },
    path: '/sales/my-tenders/:id/print',
    name: 'PrintUserTender',
    component: () => import(/* webpackChunkName: "print-tender" */ '@/views/print/print-pages/PagePrintTender'),
  },
];
