import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SUPPORT_RELEASE_NOTE = {
  name: 'SupportReleaseNote',
  vocabularies: ['release_notes'],
};

export default [
  {
    path: 'release-notes',
    redirect: '/support/release-notes',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'release_notes',
          middleware: [validateComponent],
          breadcrumb: {
            name: 'Release notes',
          },
          dict: {
            name: 'SupportReleaseNotes',
            vocabularies: ['search', 'release_notes'],
          },
          menuId: 61,
        },
        path: '/support/release-notes',
        name: 'SupportReleaseNotes',
        component: () =>
          import(/* webpackChunkName: "support-release-notes" */ '@/views/support/release-notes/ListReleaseNotes'),
      },
      {
        meta: {
          breadcrumb: { name: 'Create release note' },
          component: 'release_notes',
          middleware: [validateComponent],
          dict: DICTIONARY_SUPPORT_RELEASE_NOTE,
          parentMenuId: 61,
        },
        path: 'new',
        name: 'SupportEditorReleaseNoteNew',
        component: () =>
          import(
            /* webpackChunkName: "support-release-note-editor" */ '@/views/support/release-notes/EditorReleaseNote'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit release note', dynamic: true },
          component: 'release_notes',
          middleware: [validateComponent],
          dict: DICTIONARY_SUPPORT_RELEASE_NOTE,
          parentMenuId: 61,
        },
        path: ':id/edit',
        name: 'SupportEditorReleaseNote',
        component: () =>
          import(
            /* webpackChunkName: "support-release-note-editor" */ '@/views/support/release-notes/EditorReleaseNote'
          ),
      },
    ],
  },
];
