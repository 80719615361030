import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_ALL_CONTACTS = {
  name: 'AllContacts',
  vocabularies: ['contacts', 'entity_list', 'search', 'contacts', 'notes', 'activities'],
};

const DICTIONARY_CONTACT = {
  name: 'Contact',
  vocabularies: [
    'entity_list',
    'contacts',
    'triggers',
    'project',
    'projects',
    'companies',
    'tenders',
    'contacts',
    'activities',
    'notes',
    'activities',
    'search',
  ],
};

export const contacts = [
  {
    path: 'contacts',
    redirect: '/leads/contacts',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/leads/contacts',
        name: 'Contacts',
        component: () => import(/* webpackChunkName: "all-contacts" */ '@/views/leads/AllContacts'),
        meta: {
          component: 'all_contacts',
          middleware: [validateComponent],
          breadcrumb: { name: 'all_contacts' },
          dict: DICTIONARY_ALL_CONTACTS,
          menuId: 11,
        },
      },
      {
        meta: {
          breadcrumb: { name: 'person', dynamic: true },
          component: 'contact',
          middleware: [validateComponent],
          dict: DICTIONARY_CONTACT,
          parentMenuId: 11,
        },
        path: ':id',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/leads/Contact'),
      },
    ],
  },
];

export const printContacts = [
  {
    meta: {
      breadcrumb: { name: 'print' },
      component: 'print',
      middleware: [validateComponent],
      dict: {
        name: 'MyContacts',
        vocabularies: ['contacts', 'entity_list', 'search', 'contacts', 'notes', 'activities'],
      },
    },
    path: '/leads/contacts/print',
    name: 'PrintContacts',
    component: () => import(/* webpackChunkName: "print-contacts" */ '@/views/print/PrintContacts'),
  },
  {
    meta: {
      breadcrumb: { name: 'print', dynamic: true },
      component: 'print',
      middleware: [validateComponent],
      dict: DICTIONARY_CONTACT,
    },
    path: '/leads/contacts/:id/print',
    name: 'PrintContact',
    component: () => import(/* webpackChunkName: "print-contact" */ '@/views/print/print-pages/PagePrintContact'),
  },
];
