import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SYSTEM_MODULES = {
  name: 'SystemModules',
  vocabularies: ['system_modules'],
};

export default [
  {
    path: 'modules',
    redirect: '/system/modules',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'system_modules',
          middleware: [validateComponent],
          breadcrumb: {
            name: 'Modules',
          },
          menuId: 37,
          dict: DICTIONARY_SYSTEM_MODULES,
        },
        path: '/system/modules',
        name: 'SystemModules',
        component: () =>
          import(/* webpackChunkName: "system-modules" */ '@/views/system/modules/ListModules'),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit module', dynamic: true },
          component: 'system_modules',
          middleware: [validateComponent],
          parentMenuId: 37,
          dict: DICTIONARY_SYSTEM_MODULES,
        },
        path: ':id/edit',
        name: 'SystemEditorModule',
        component: () =>
          import(
            /* webpackChunkName: "system-module-editor" */ '@/views/system/modules/EditorModule'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Create module' },
          component: 'system_modules',
          middleware: [validateComponent],
          parentMenuId: 37,
          dict: DICTIONARY_SYSTEM_MODULES,
        },
        path: 'new',
        name: 'SystemEditorModuleNew',
        component: () =>
          import(
            /* webpackChunkName: "system-module-editor" */ '@/views/system/modules/EditorModule'
          ),
      },
    ],
  },
];
