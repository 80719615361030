function disableDocumentScrolling() {
  if (document.documentElement.style.position !== 'fixed') {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    const cbCompensateObjects = document.querySelectorAll('.sb-compensate');
    let topVerticalOffset = 0;
    if (typeof window.pageYOffset !== 'undefined') {
      topVerticalOffset = window.pageYOffset;
    } else if (document.documentElement.scrollTop) {
      topVerticalOffset = document.documentElement.scrollTop;
    }
    document.documentElement.classList.add('scroll-disabled');
    document.documentElement.style.position = 'fixed';
    document.documentElement.style.marginTop = `-${topVerticalOffset}px`;
    // document.documentElement.style.paddingRight = `${scrollBarWidth}px`;
    for (let i = 0; i < cbCompensateObjects.length; i += 1) {
      cbCompensateObjects[i].style.paddingRight = `${scrollBarWidth}px`;
    }
  }
}

function enableDocumentScrolling() {
  if (document.documentElement.style.position === 'fixed') {
    const cbCompensateObjects = document.querySelectorAll('.sb-compensate');
    document.documentElement.style.position = null;
    const scrollPosition = -1 * parseFloat(document.documentElement.style.marginTop);
    document.documentElement.classList.remove('scroll-disabled');
    document.documentElement.style.marginTop = null;
    // document.documentElement.style.paddingRight = null;
    for (let i = 0; i < cbCompensateObjects.length; i += 1) {
      cbCompensateObjects[i].style.paddingRight = null;
    }
    window.scrollTo(0, scrollPosition);
  }
}

export { disableDocumentScrolling, enableDocumentScrolling };
