import _ from 'lodash';
import Vue from 'vue';
import {
  initUser,
  patchUser,
} from '@/api/repositories/userRepository';
import {
  patchClientUser,
  activateClientUser,
} from '@/api/repositories/clientsRepository';
import {
  getUserSettings,
  getUserContentStats,
  getUserStatus,
} from '@/api/repositories/userSettingsRepository';
import {
  auth,
  logout,
} from "@/api/repositories/authRepository";
import vars from "@/config/vars";
import { mergeUserSelections } from "@/shared/user";
import { getApiClients } from "@/api/repositories/apiClientsRepository";
import {
  SMART_USER_STATUSES,
  INVITE_STATUSES,
} from "@/config/enums";
import {
  getCurrencySymbol,
} from "@/shared/formatters";
import { getSubscriptions } from "@/api/repositories/subscriptionRepository";
import { Subscription } from "@/entities";
import { isJSON, wait } from "@/shared/utils";
import { getAuthOpid, setAuthOpid } from "@/shared/account";
import router from "@/router";

const { DateTime } = require('luxon');

const initialState = () => ({
  contentStats: {},
  setupStatus: {},
  settings: {},
  apiClients: null,
});

async function checkUserLanguage(store, payload = {}) {
  const { getters, dispatch } = store;
  const { isChangeUserSettings } = payload;
  const uiLangId = Vue.prototype.$getDictLanguage('id');
  const userLangId = getters['getSettingsValue']('user.language_id');

  if (userLangId === uiLangId) {
    return;
  }

  if (isChangeUserSettings) {
    await dispatch('changeLanguage', { langId: uiLangId });
  } else {
    await Vue.prototype.$changeDictLanguage({
      langId: userLangId,
      silent: true,
    });
  }
}

function prepareUserSettings(userSettings = {}) {
  const selections = userSettings.selections || [];

  return {
    ...userSettings,
    merged_selections: mergeUserSelections(selections),
  };
}

export default {
  state: initialState(),
  getters: {
    apiClients(state) {
      return state.apiClients || [];
    },
    userId(state) {
      return state.settings.user?.id;
    },
    client(state, getters) {
      const currentClientInfo = getters.getSettingsValue('client') || {};
      const userClients = getters.getSettingsValue('clients') || [];
      const currentClientFromClients = userClients.find(client => client.id === currentClientInfo.id) || {};

      return {
        ...currentClientInfo,
        ...currentClientFromClients,
      };
    },
    activeSubscriptions(state, getters) {
      const subscriptions = getters.client.subscriptions || [];
      return subscriptions.filter(subscription => {
        const { end_date } = subscription;
        return !end_date || DateTime.fromISO(end_date) > DateTime.now();
      });
    },
    userMainData(state) {
      const { user } = state.settings;

      return {
        id: user.id,
        name: user.name,
        email: user.email,
        pic: user.pic,
      };
    },
    isSSO(state, getters) {
      const ssoProvider = getters.getSettingsValue('client.sso_provider_id');
      return ssoProvider && ssoProvider > 1;
    },
    isAuthorized: (state) => () => {
      const authOpid = getAuthOpid();
      return Object.keys(state.settings).length > 0 && authOpid;
    },
    userCurrencySymbol(state, getters) {
      const currencyId = getters['getSettingsValue']('user.currency_id');
      return getCurrencySymbol(currencyId);
    },
    setupStatus(state) {
      const setupStatus = state.setupStatus || {};

      return {
        valid: false,
        steps: {},
        percent: 0,
        ...setupStatus,
      };
    },
    selectionsValueMin(state, getters) {
      return getters['getSelectionsValue']('value_min', 0);
    },
    selectionsValueMax(state, getters) {
      return getters['getSelectionsValue']('value_max', vars.CONST_MAX_BUDGET_LIMIT);
    },
    mergedSelections(state) {
      return state.settings.merged_selections;
    },
    liveClients(state) {
      const { clients = [] } = state.settings;
      return clients.filter(client => {
        if (client.invite_status !== INVITE_STATUSES.SENT) {
          return false;
        }

        const subscriptions = client.subscriptions || [];
        return subscriptions.some(subscription => {
          const { end_date } = subscription;
          return !end_date || DateTime.fromISO(end_date) > DateTime.now();
        });
      });
    },
    userUpdatesNotifMode: (state, getters) => {
      const notifMode = getters.getSettingsValue('user.updates_notif_mode');
      return typeof notifMode === 'string' && isJSON(notifMode) ? JSON.parse(notifMode) : notifMode;
    },
    notificationSettingsNotifKey: (state, getters) => {
      const userId = getters.getSettingsValue('user.id');
      const clientId = getters.getSettingsValue('client.id');

      return `notification_time_settings_user_${userId}_client_${clientId}`;
    },
    onlyCountry: (state, getters) => (countryId) => {
      const userCountries = getters['getSelectionsValue']('countries');
      return userCountries.length === 1 && userCountries.includes(countryId);
    },
    historyStartDate: (state, getters) => {
      let historyStartDate = null;

      getters.activeSubscriptions.forEach(subscription => {
        const startDate = subscription.project_history_start_date || null;

        if (!historyStartDate || DateTime.fromISO(startDate) < DateTime.fromISO(historyStartDate)) {
          historyStartDate = startDate;
        }
      });

      return historyStartDate;
    },
    hasComponent: (state) => (componentKey) => {
      const {
        components = [],
      } = state.settings.user || {};
      return components.includes(componentKey);
    },
    getUserContentStats: (state) => (statKey) => {
      return state.contentStats[statKey] || [];
    },
    getSettingsValue: (state) => (key) => {
      return _.get(state.settings, key);
    },
    getSelectionsValue: (state) => (components, def = []) => {
      return _.get(state.settings, `merged_selections.${components}`) || def;
    },
    getLimitedDateByStartHistory: (state, getters) => (date) => {
      const historyStartDate = getters['historyStartDate'] ?
        DateTime.fromISO(getters['historyStartDate']) : DateTime.utc();

      return date && date < historyStartDate ? historyStartDate : date;
    },
    userCountry: (state, getters) => {
      return getters['getSettingsValue']('client.country_id');
    },
    userCountries: (state, getters) => {
      return getters['getSelectionsValue']('countries');
    },
  },
  mutations: {
    SET_USER_SETTINGS(state, payload) {
      state.settings = _.cloneDeep(payload);
    },
    UPDATE_USER_SETTINGS(state, payload = {}) {
      _.merge(state.settings, payload);
      // state.settings = Object.assign({}, state.settings, payload);
    },
    CLEAR_USER_SETTINGS(state) {
      state.settings = {};
    },
    UPDATE_USER_CONTENT_STATS(state, contentStats = {}) {
      Object.keys(contentStats).forEach(statKey => {
        Vue.set(state.contentStats, statKey, contentStats[statKey]);
      });
    },
    SET_SETUP_STATUS(state, payload) {
      state.setupStatus = _.cloneDeep(payload);
    },
    SET_USER_API_CLIENTS(state, apiClients) {
      state.apiClients = apiClients;
    },
    RESET_ACCOUNT(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async fetchUserSettings({ commit }, payload = {}) {
      const {
        stored = true,
        opid,
      } = payload;
      let settingsData = payload.userSettings;
      let settings;

      if (!settingsData) {
        try {
          const response = await getUserSettings({ opid });
          settingsData = response?.data || {};
        } catch (error) { }
      }

      if (!settingsData) {
        return Promise.reject();
      } else {
        settings = prepareUserSettings(settingsData);
      }

      if (stored) {
        commit('SET_USER_SETTINGS', settings);
      }

      return settings;
    },
    async fetchUserContentStats({ commit }, payload = {}) {
      const {
        entityTypeId,
        isShowLocked,
      } = payload;
      const query = {};
      let response;

      if (entityTypeId) {
        query.filter = {
          entity_type_id: {
            eq: entityTypeId,
          },
        };
      }

      if (isShowLocked) {
        query.show_locked = 1;
      }

      try {
        response = await getUserContentStats(query);

        if (response) {
          commit('UPDATE_USER_CONTENT_STATS', response);
        }
      } catch (error) { }

      return response;
    },
    async fetchSetupStatus({ commit }, payload = {}) {
      const {
        stored = true,
        opid,
      } = payload;
      let response;

      try {
        response = await getUserStatus({ opid });
      } catch (error) { }

      if (response && stored) {
        commit('SET_SETUP_STATUS', response);
      }

      return response;
    },
    async fetchApiClients({ getters, commit, state }) {
      if (state.apiClients) {
        return;
      }

      const clientId = getters['getSettingsValue']('client.id');
      const query = {
        limit: 100,
        filter: {
          client_id: {
            in: [clientId],
          },
        },
      };
      let apiClients = [];

      try {
        const response = await getApiClients(query);
        apiClients = response?.data;
      } catch (e) { }

      commit('SET_USER_API_CLIENTS', apiClients);

      return apiClients;
    },
    async fetchAuthData({ dispatch, commit }, payload = {}) {
      const {
        userSettings,
        opid,
        beforeStoreFunc = () => { },
      } = payload;
      const fetchRequests = [
        dispatch('fetchUserSettings', {
          userSettings,
          stored: false,
          opid,
        }),
        dispatch('fetchSetupStatus', {
          stored: false,
          opid,
        }),
      ];
      let responses;

      try {
        responses = await Promise.all(fetchRequests);
      } catch (e) {
        return Promise.reject();
      }

      beforeStoreFunc();

      commit('SET_USER_SETTINGS', responses[0]);
      commit('SET_SETUP_STATUS', responses[1]);
    },
    async activateUser({ commit, getters }) {
      if (!getters['setupStatus']?.valid ||
        getters['getSettingsValue']('user.status') === SMART_USER_STATUSES.LIVE ||
        getters['getSettingsValue']('user.is_dummy') ||
        window.logged_as_user) {
        return;
      }

      let res = null;

      try {
        res = await activateClientUser(getters['getSettingsValue']('client.id'), getters['userId']);

        if (!res) {
          throw new Error('Failed to activate the user');
        }

        commit('UPDATE_USER_SETTINGS', {
          user: {
            status: SMART_USER_STATUSES.LIVE,
          },
        });
      } catch (error) {
        console.log(error);
      }

      if (getters['isSSO']) {
        await router.push({
          name: 'Logout',
          params: {
            sso_relogin: !!res,
          },
        });
      }
    },
    async updateUserSettings({ commit, state, getters }, payload = {}) {
      const { user } = payload;

      if (!user) {
        return;
      }

      const userFields = [
        'email',
        'name',
        'phone',
        'pic',
      ];
      const clientUserFields = [
        'language_id',
        'license_id',
        'currency_id',
        'timezone',
        'updates_notif_mode',
      ];
      const userData = {};
      const clientData = {};
      const userId = getters['userId'];

      Object.keys(user).forEach(valueKey => {
        const value = user[valueKey];

        if (userFields.includes(valueKey)) {
          userData[valueKey] = value;
        } else if (clientUserFields.includes(valueKey)) {
          clientData[valueKey] = value;
        }
      });

      if (Object.keys(userData).length > 0) {
        const res = await patchUser(userId, userData);

        if (res) {
          commit('UPDATE_USER_SETTINGS', {
            user: userData,
          });
        }
      }

      if (Object.keys(clientData).length > 0) {
        const clientId = getters.getSettingsValue('client.id');
        const patchData = Object.entries(clientData).reduce((acum, [key, value]) => ({
          ...acum,
          [`smart_settings/${key}`]: value,
        }), {});
        const res = await patchClientUser({
          clientId,
          userId,
          data: patchData,
          params: {
            force: true,
          },
        });

        if (res) {
          commit('UPDATE_USER_SETTINGS', {
            user: clientData,
          });
        }
      }

      return getters.getSettingsValue('user');
    },
    async changeLanguage({ getters, dispatch }, { langId }) {
      if (!getters['isAuthorized'] || window.logged_as_user) {
        return;
      }

      await dispatch('updateUserSettings', {
        user: {
          language_id: langId
        },
      });
    },
    async reLogin({ getters, dispatch, commit }, payload = {}) {
      const {
        opid,
      } = payload;

      if (!opid) {
        return;
      }

      try {
        await dispatch('fetchAuthData', {
          opid,
          beforeStoreFunc: () => {
            dispatch('resetAuthData', null, {
              root: true,
            });
          },
        });
      } catch (e) {
        return Promise.reject(e);
      }

      setAuthOpid(opid);

      await wait();
      await checkUserLanguage({ getters, dispatch });
    },
    async login({ getters, commit, dispatch }, payload = {}) {
      const {
        opid,
        authData,
        loginAsUser,
        userSettings,
      } = payload;
      let authOpid = opid;

      dispatch('logout');

      if (!authOpid) {
        try {
          const response = await auth(authData);
          authOpid = response?.opid;
        } catch (e) {
          return Promise.reject(e);
        }
      }

      if (!authOpid) {
        return Promise.reject();
      } else {
        setAuthOpid(authOpid);
      }

      try {
        await wait();
        await dispatch('fetchAuthData', {
          userSettings,
        });
      } catch (e) {
        dispatch('logout');
        return Promise.reject(e);
      }

      await dispatch('activateUser');

      //
      await checkUserLanguage({ getters, dispatch }, {
        isChangeUserSettings: !loginAsUser,
      });

      return true;
    },
    async initUser({ dispatch, commit, getters }, data = {}) {
      const response = await initUser(getters.userId, {
        name: data.name,
        phone: data.phone,
        password: data.password,
        oid: null,
      });

      if (response) {
        commit('UPDATE_USER_SETTINGS', {
          user: {
            name: data.name,
            phone: data.phone,
            is_dummy: false,
          },
        });

        await dispatch('activateUser');
      }
    },
    logout({ dispatch }) {
      logout().catch(() => { });
      dispatch('resetAuthData', null, {
        root: true,
      });
    },
  },
  namespaced: true,
};
