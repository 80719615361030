import Vue from 'vue';
import _compact from 'lodash/compact';
import _head from 'lodash/head';
import _last from 'lodash/last';
import store from '@/store';
import { getExponent, roundNumber } from '@/shared/utils';

function isValidNumberForFormatted(val) {
  return (typeof val === 'string' && !val) || typeof val === 'number';
}

function getFormattedNumberParts(number, options) {
  const lang = Vue.prototype.$getDictLanguage('intlIso');
  const { currencyId, ...formatOptions } = options;
  let formatParts;
  let currency;

  if (formatOptions.style === 'currency') {
    currency = Vue.prototype.$lFind('global.currencies', {
      id: currencyId || store.getters['Account/getSettingsValue']('user.currency_id'),
    });

    formatOptions.currency = currency?.code;
  }

  try {
    formatParts = new Intl.NumberFormat(lang, formatOptions).formatToParts(number);
  } catch (error) {
    // fallback to a simple currency representation
    formatParts = [
      {
        type: 'integer',
        value: number,
      },
    ];

    if (currency?.symbol) {
      formatParts.push({
        type: 'currency',
        value: currency.symbol,
      });
    }
  }

  return formatParts;
}

export function getCurrencySymbol(currencyId, number = 0) {
  const formatParts = getFormattedNumberParts(number, {
    style: 'currency',
    notation: 'compact',
    currencyId,
  });
  const currencyIndex = formatParts.findIndex((el) => el.type === 'currency');

  const formatObject = formatParts.reduce(
    (obj, part) => ({
      ...obj,
      [part.type]: part.value,
    }),
    {},
  );
  const { currency, compact } = formatObject;
  let symbolWithCompactArr;
  let symbolSide;

  if (currencyIndex > 0) {
    symbolSide = 'right';
    symbolWithCompactArr = [compact, currency];
  } else {
    symbolSide = 'left';
    symbolWithCompactArr = [currency, compact];
  }

  return {
    symbol: currency,
    symbolSide,
    compact,
    symbolWithCompact: _compact(symbolWithCompactArr).join(' '),
  };
}

/* Get a string from a number in short form (e.g. 10K, 1,000.5M) */
export function shortFormatNumber(number) {
  const lang = Vue.prototype.$getDictLanguage('intlIso');
  const roundedNumber = Math.ceil(number);
  return new Intl.NumberFormat(lang, {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(roundedNumber);
}

export function formatNumberToParts(num, options = {}) {
  const opts = {
    decimal: false,
    round: false,
    formatOptions: {},
    ...options,
  };
  const exponent = getExponent(num);
  const numFormatOptions = {};
  let number = num;

  if (opts.round) {
    number = roundNumber(number);
  }

  if (exponent >= 6) {
    Object.assign(numFormatOptions, {
      notation: 'compact',
    });
  } else if (opts.decimal) {
    // number = Math.round((number + Number.EPSILON) * 100) / 100;

    Object.assign(numFormatOptions, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }

  Object.assign(numFormatOptions, opts.formatOptions);
  return getFormattedNumberParts(number, numFormatOptions);
}

export function formatNumber(num, options = {}) {
  if (!isValidNumberForFormatted(num)) {
    return '';
  }

  const parts = formatNumberToParts(num, options);
  return parts.reduce((string, part) => string + part.value, '');
}

export function formatRangeNumber(value, options) {
  const vals = Array.isArray(value) ? [_head(value), _last(value)] : [value];
  const range = vals
    .reduce((acum, val) => (val !== null && !acum.includes(val) ? [...acum, val] : acum), [])
    .map((val) => formatNumber(val, options));

  return range.join(' - ');
}

export function formatSqearMeter(val) {
  return val ? `${formatNumber(val)} m²` : '';
}

export function formatCubicMeter(val) {
  return val ? `${formatNumber(val)} m³` : '';
}
